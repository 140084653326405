import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import FormGroup from '@material-ui/core/FormGroup';

import RadioGroup from '@material-ui/core/RadioGroup';
import { Robo, HomeSampling } from './firebase';


import FormLabel from '@material-ui/core/FormLabel';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";


import * as admin from 'firebase-admin';

import MenuItem from '@material-ui/core/MenuItem';


import Select from '@material-ui/core/Select';

import Autocomplete from "@material-ui/lab/Autocomplete";

import InputLabel from '@material-ui/core/InputLabel';

import ReactLoading from 'react-loading';


import DateFnsUtils from '@date-io/date-fns';

import { withStyles } from '@material-ui/core/styles';

import DialogActions from "@material-ui/core/DialogActions";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Grid from "@material-ui/core/Grid";

import Paper from "@material-ui/core/Paper";

import FormControlLabel from "@material-ui/core/FormControlLabel";

import Checkbox from "@material-ui/core/Checkbox";

import Slide from "@material-ui/core/Slide";

import Modal from "@material-ui/core/Modal";

import PictureAsPdfSharpIcon from "@material-ui/icons/PictureAsPdfSharp";

import Switch from "@material-ui/core/Switch";

import Invoice_Table from "./table";

import MaterialTable from "material-table";

import FormControl from "@material-ui/core/FormControl";

import { green } from '@material-ui/core/colors';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ReceiptIcon from '@material-ui/icons/Receipt';



import Radio from '@material-ui/core/Radio';

import { addYears, set, max } from "date-fns";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
function msToTime(ms) {
  var seconds = (ms / 1000);
  var minutes = parseInt(seconds / 60, 10);
  seconds = seconds % 60;
  var hours = parseInt(minutes / 60, 10);
  minutes = minutes % 60;

  return addZero(hours) + ':' + addZero(minutes);
}

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  button: {
    color: 'white'
  },
  paper: {
    width: 1200,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    margin: 'auto',
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  },
}));

const formatAMPM = (date) => {
  var hours = date.getHours();
  var minutes = date.getMinutes()
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes
  return strTime;
}


export default function Invoice() {
  const classes = useStyles();

  // 8 Apr 2021
  const [ImportRecord, setImportRecord] = React.useState([])


  const [selectedValue, setSelectedValue] = React.useState('Normal');
  const [classicModal, setClassicModal] = React.useState(false)
  const [search_table, setSearch_table] = React.useState([]);
  const [age, setAge] = React.useState("");
  const [lab_tests, setLab_test] = React.useState([]);
  const [branches, setBranches] = React.useState([])
  const [branch, setBranch] = React.useState("")
  const history = useHistory();

  // Group of Tests 
  const [openGroupTests,setOpenGroupTests]=React.useState(false)
  const [GroupTestInfo,setGroupTestInfo]=React.useState([])
// Show detail of test 17-dec-2022
  const [DetailShow,setDetailShow]=React.useState(true)

  const [columns, setColumns] = React.useState([
    { title: 'Code', field: 'code', editable: 'never' },
    // { title: 'Title', field: 'title' },
// Show detail of test 17-dec-2022
    // this field comment before 17-dec
    { title: 'Parameters', field: 'GroupTestDetails' }, 
    // { title: 'DETAILSB', field: 'detail' }, // ye button k lye lgaya tha

    { title: 'Description', field: 'description', type: 'numeric', editable: 'never' },
    {
      title: 'Cost', field: 'cost', type: 'numeric', editable: 'never', render: rowData => {
        return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>
      }
    },
    {
      title: 'Discount %', field: 'discount_percent', type: 'numeric', editable: selectedValue.includes('Normal') ? "onUpdate" : "never", render: rowData => {
        return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent + "%"}</p>
      }
    },
    {
      title: 'Discount Value', field: 'discount_value', type: 'numeric', editable: 'never', render: rowData => {
        return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>
      }
    },
    {
      title: 'Special Discount', field: 'special_discount', type: 'numeric', editable: 'never', render: rowData => {
        return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>
      }
    },
    {
      title: 'Net Cost', field: 'net_cost', type: 'numeric', editable: 'never', render: rowData => {
        return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>
      }
    },
    // {
    //   title: 'Category',
    //   field: 'category',
    //   render: rowData => (
    //     <Select
    //       value={rowData.category}
    //       onChange={(e) => handleCategoryChange(rowData, e.target.value)}
    //     >
    //       <MenuItem value="Electronics">Electronics</MenuItem>
    //       <MenuItem value="Clothing">Clothing</MenuItem>
    //       <MenuItem value="Grocery">Grocery</MenuItem>
    //     </Select>
    //   ),
    // },
    // 30-sep-2024
    {
      title: 'Urgent',
      field: 'urgent',
      type: 'boolean', // Renders a checkbox
      editable: 'onUpdate', // Allows editing on row update
      render: rowData => <Checkbox checked={rowData.urgent} 
      onChange={(e)=>{handleCheckboxChange(e,rowData)}}
      />
    },
    {
      title: 'Sample Outside',
      field: 'sampleoutside',
      type: 'boolean', // Renders a checkbox
      editable: 'onUpdate', // Allows editing on row update
      render: rowData => <Checkbox checked={rowData.sampleoutside} 
      onChange={(e)=>{handleCheckboxChangeSampleOutside(e,rowData)}}
      />
    },
    // {
    //   title: 'Is Verified',
    //   field: 'is_verified',
    //   type: 'boolean', // Renders a checkbox
    //   editable: 'onUpdate', // Allows editing on row update
    //   render: rowData => <Checkbox checked={rowData.is_verified} />
    // }
    
  ]);

  
  const [cc_columns, setCC_columns] = React.useState([
    { title: 'Date', field: 'date', editable: 'never' },
    { title: 'Cheque/ Transaction #', field: 'cc', editable: 'never' },
    { title: 'Amount', field: 'amount', type: 'numeric', editable: 'never' },
    { title: 'Remarks', field: 'remarks', editable: 'never' },
    { title: 'Mode', field: 'mode', editable: 'never' },
    { title: 'Shift ID', field: 'shift_id', editable: 'never' },
    { title: 'User ID', field: 'user_id', editable: 'never' },
    { title: 'User Name', field: 'user_name', editable: 'never' }
  ]);


  const [data, setData] = React.useState([]);

  const [CC, setCC] = React.useState([]);


  const [consentForm, setconsentForm] = React.useState('')

  const [patient_history, setPatientHistory] = React.useState([]);
  const [panel, setPanel] = React.useState("");
  const [total_bill, setTotal_Bill] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectOption, setSelectOption] = React.useState({});
  const [openHist, setOpenHist] = React.useState();
  const [ticket_no, setTicket_No] = React.useState('')
  const [TempDiscount, setTempDiscount] = React.useState(0)
  const [flight_no, setFlight_No] = React.useState('')
  const [flight_date, setFlight_Date] = React.useState(new Date('01/01/1900'))
  const [flight_time, setFlight_time] = React.useState(0)

  const [National,setNational]=React.useState("true")

  const [ImportPatientID, setImportPatientID] = React.useState('')

  const [Entitlement_Id, setEntitlement_Id] = React.useState(0)
  const [Entitlement_Name, setEntitlement_Name] = React.useState('')
  const [Entitlement_Amount, setEntitlement_Amount] = React.useState(0)

  const [airline, setAirline] = React.useState('')
  const [destination, setDestination] = React.useState('')
  const [airport, setAirport] = React.useState('')
  const [NormalCase, setNormalCase] = React.useState(true)
  const [country, setCountry] = React.useState('')
  const [CNIC, setCNIC] = React.useState('')
  const [saveInvoice, setSaveInvoice] = React.useState(false)
  const [passport, setPassport] = React.useState('')
  const [salesAgent, setSalesAgent] = React.useState('Select')
  const [salesAgentNameList, setSalesAgentNameList] = React.useState([])

  const [AllowDiscount, setAllowDiscount] = React.useState(false)

  const [ImportMenu, setImportMenu] = React.useState(false)

  const [DoctorNameList, setDoctorNameList] = React.useState([])

  const [salesAgentList, setSalesAgentList] = React.useState([])
  const [selectBranch, setSelectBranch] = React.useState('')
  const [panelList, setPanelList] = React.useState([])
  const [otherPanelCase, setOtherPanelCase] = React.useState(false)
  const [TestList, setTestList] = React.useState([])
  const [total_cost, setTotal_cost] = React.useState(0)
  const [total_discount_value, setTotalDiscountValue] = React.useState(0)
  const [total_special_discount, setTotalSpecialDiscount] = React.useState(0)
  const [total_net_cost, setTotalNetCost] = React.useState(0)
  const [pdfScanFlag,setpdfScanFlag]=React.useState(false)

  const [Comments_for_Patient, setComments_for_Patient] = React.useState('')
  const [Invoice_Remarks, setInvoiceRemarks] = React.useState('')

  const [Selected_Test_Code_List, setSelected_Test_Code_List] = React.useState([])

  const [TestCallViaName, setTestCallViaName] = React.useState([])


  const [cc_amount, setCCAmount] = React.useState(0)
  const [cc_date, setCCDate] = React.useState(new Date())
  const [cc_remarks, setCCRemarks] = React.useState('')
  const [cc_num, setCCNum] = React.useState('')

  const [Invoice_Total_Value, setInvoiceTotalValue] = React.useState(0)
  const [Discount, setDiscount] = React.useState(0)
  const [NetPayableValue, setNetPayableValue] = React.useState(0)

  const [Received_Payment, setReceived_Payment] = React.useState(0)
  const [Balance_Payment, setBalance_Payment] = React.useState(0)

  const [cash, setCash] = React.useState(true)
  const [credit_card, setCreditCard] = React.useState(false)
  const [dd, setDD] = React.useState(false)
  const [cheque, setCheque] = React.useState(false)

  const [DoctorList, setDoctorList] = React.useState([])
  // 10-july-2024
  const [Ref_By_Name, setRef_By_Name] = React.useState([])

  const [Ref_By_Dr, setRef_By_Dr] = React.useState('')
  const [Ref_No, setRef_No] = React.useState('')

  const [ScanImage, setScanImage] = React.useState('')
  const [ScanPDF, setScanPDF] = React.useState('')
  const [ToAddress, setToAddress] = React.useState('')

  // Panel Discount of Panel Patient 
  const [panelObj,setpanelObj]=React.useState({})
    ///  Boolean of turkey


    const [turkishImageBoolean, setTurkishImageBoolean] = React.useState(false)
    const [turkishPassportBoolean,setTurkishPassportBoolean] = React.useState(false)
    const [PatientImage, setPatientImage] = React.useState("")

    // 2 Aug 2022
    const [panelType,setpanelType]=React.useState('')
  
    //////////////////////

    // Opened Shift Closing 
    const [shifts,setShifts]=React.useState([])
    const [OpenShifts,setOpenShifts]=React.useState(false)
    

  const [DiscountPrice, setDiscountPrice] = React.useState(0)


  /// 2 Nov 2021
  const [DiscountLimit,setDiscountLimit]=React.useState(100)
  const [DiscountTestLimit,setDiscountTestLimit]=React.useState("")

  // 21 Jan 2022
  // Passport

  const [PassportImage,setPassportImage]=React.useState("")

  // Sampling Picture
  const [SamplingPicture,setSamplingPicture]=React.useState("")

  // 04 Mar 2022
  const [airlineList,setAirlineList]=React.useState({})
  const [airlineType,setAirlineType]=React.useState("NON-TRAVELLER")
  const [airlineName,setairlineName]=React.useState("")

  // 07 Mar 2022
  const [airlineOherFlag,setAirlineOtherFlag]=React.useState(false)

  // 8 Apr 2022
  const [groupList,setGroupList] =React.useState([])
  const [openGroupCall,setGroupCall]=React.useState(false)
  // 20 jan 2023 
  const [foccheck, setfoccheck] = React.useState(false)
  const [foccheckCond, setfoccheckCond] = React.useState(false)
  // 30-sep-2024
  const [urgentArray, seturgentArray] = React.useState([])
  const [sampleOutsideArray, setsampleOutsideArray] = React.useState([])



// 30-sep-2024
const handleCheckboxChange = (event,rowData) => {
  console.log(event.target.checked)
  if(urgentArray.length<1){
    urgentArray.push({
      ...rowData,
      urgent:event.target.checked
    })
    console.log(urgentArray)
  console.log("data")
  }
  else{
    // const result = urgentArray.find(obj => obj.code === rowData.code);
    // findIndex(obj => obj.code === rowData.code);
    const index = urgentArray.findIndex(obj => obj.code === rowData.code);
   if(index !== -1){
    const removedObject = urgentArray.splice(index, 1); 
    console.log(removedObject)
    console.log("yes found")
   }
   else{
    urgentArray.push({
      ...rowData,
      urgent:event.target.checked
    })
    console.log("not found in result")
   }
  
    console.log("data is")
  }
  console.log(urgentArray)
  

  // setRowData({
  //   ...rowData,
  //   is_active: event.target.checked,
  // });
};
//////////////////////
const handleFileRead = async (event) => {
  const file = event.target.files[0]
  var fileInput = document.getElementById('img');
  var filePath = fileInput.value;
  var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
  if (!allowedExtensions.exec(filePath)) {
    alert('Please upload file having extensions .jpeg/.jpg/.png only.');
    fileInput.value = '';
    setconsentForm('')
    return false;
  } else {

    const base64 = await convertBase64(file)
    setconsentForm(base64)
  }

}
// 30-sep-2024
const handleCheckboxChangeSampleOutside = (event,rowData) => {
  console.log(event.target.checked)
  if(sampleOutsideArray.length<1){
    sampleOutsideArray.push({
      ...rowData,
      urgent:event.target.checked
    })
    console.log(sampleOutsideArray)
  console.log("data")
  }
  else{
    // const result = urgentArray.find(obj => obj.code === rowData.code);
    // findIndex(obj => obj.code === rowData.code);
    const index = sampleOutsideArray.findIndex(obj => obj.code === rowData.code);
   if(index !== -1){
    const removedObject = sampleOutsideArray.splice(index, 1); 
    console.log(removedObject)
    console.log("yes found")
   }
   else{
    sampleOutsideArray.push({
      ...rowData,
      urgent:event.target.checked
    })
    console.log("not found in result")
   }
  
    console.log("data is")
  }
  console.log(sampleOutsideArray)
  

  // setRowData({
  //   ...rowData,
  //   is_active: event.target.checked,
  // });
};
///////////////////
const handleFileReadImage = async (event) => {
  const file = event.target.files[0]
  var fileInput = document.getElementById('PatientImage');
  var filePath = fileInput.value;
  var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
  if (!allowedExtensions.exec(filePath)) {
    alert('Please upload file having extensions .jpeg/.jpg/.png only.');
    fileInput.value = '';
    setPatientImage('')
    return false;
  } else {

    const base64 = await convertBase64(file)
    setPatientImage(base64)
  }

}

///////////////////
const handleFileReadPassportImage = async (event) => {
  const file = event.target.files[0]
  var fileInput = document.getElementById('PassportImage');
  var filePath = fileInput.value;
  var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
  if (!allowedExtensions.exec(filePath)) {
    alert('Please upload file having extensions .jpeg/.jpg/.png only.');
    fileInput.value = '';
    setPassportImage('')
    return false;
  } else {

    const base64 = await convertBase64(file)
    setPassportImage(base64)
  }

}
 /////////////////// Reading Sampling Picture
 const handleFileReadSamplingPicture = async (event) => {
  const file = event.target.files[0]
  var fileInput = document.getElementById('SamplingPicture');
  var filePath = fileInput.value;
  var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
  if (!allowedExtensions.exec(filePath)) {
    alert('Please upload file having extensions .jpeg/.jpg/.png only.');
    fileInput.value = '';
    setSamplingPicture('')
    return false;
  } else {

    const base64 = await convertBase64(file)
    setSamplingPicture(base64)
  }

}

  const handleScanImage = async (event) => {
    console.log("Invoice HandlerScanImage 417 :",event)
    const file = event.target.files[0]
    var fileInput = document.getElementById('ScanImage');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload file having extensions .jpeg/.jpg/.png only.');
      fileInput.value = '';
      setScanImage('')
      return false;
    } else {
      console.log("Invoice HandlerScanImage 428 before convert into base :",file)
      const base64 = await convertBase64(file)
      console.log("Invoice HandlerScanImage 430 after convert into base :",base64)
      setScanImage(base64)
    }

  }
  const handleScanPDF = async (event) => {
    const file = event.target.files[0]
    console.log(file)
    var fileInput = document.getElementById('ScanPDF');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.pdf)$/i;

    if (!allowedExtensions.exec(filePath)) {
      alert('Please upload file having extensions .pdf only.');
      fileInput.value = '';
      setScanPDF('')
      return false;
    } else {
      const base64 = await convertBase64(file)
      console.log("after convert into base64"+base64)
      // console.log("after convert into base64"+base64.split('base64,')[1])

      setScanPDF(base64)
    }

  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    localStorage.setItem('panel_id', event.target.value)
    if (event.target.value == 'Normal') {
      setFlight_No('')
      setTicket_No('')
      setAirline('')
      setDestination('')
      setAirport('')
      setData([])
      setTotal_cost(0)
      setTotalSpecialDiscount(0)
      setTotalNetCost(0)
      setBalance_Payment(0)
      setReceived_Payment(0)
      setCC([])
      setSelected_Test_Code_List([])
      setNormalCase(true)
    } else {
      setData([])
      setTotal_cost(0)
      setTotalSpecialDiscount(0)
      setTotalNetCost(0)
      setBalance_Payment(0)
      setReceived_Payment(0)
      setSelected_Test_Code_List([])
      setCC([])
      setNormalCase(false)
    }
    
  };

// 20-jan ye function dismiss kr dya hai ye FOC apply krny se 100%discount de rha tha us se groupId null ho rhi thi

const FOChandler=async()=>{
  setTempDiscount(100)
  if (Entitlement_Name != '') {
    alert("Entitlement gives 100% Discount!!")
    setDiscount(100)
    setTempDiscount(100)

    return
  }

  var dis = parseInt(DiscountPrice)
  // Percentage of Discount
  
  if (100 != 0) {
    dis = parseInt(((total_cost - total_discount_value) / 100) * 100)
    setDiscountPrice(dis)
  }
  // Discount Price

  if (DiscountPrice != 0) {
    if (DiscountPrice > (total_cost - total_discount_value)) {
      setDiscountPrice(dis)
      alert("Discount can't be more than Payable Amount")
      return
    }
  }
  // Update Received
  if(100>DiscountLimit || parseInt((dis / (total_cost - total_discount_value)) * 100) > DiscountLimit){
    alert("Discount Limit of Test "+DiscountTestLimit+" = "+DiscountLimit+"%. Please Apply Individual Discount.")
    setDiscountPrice(dis)
    setTempDiscount(100)
    return
  }
 

  // Each Test Price Calculation

  var temp_net_cost = 0
  var payable = 0
  var counter = 0
  var index = 0
  var calculating_discount = 0
  console.log(data)
  var hold = await data.map((row) => {
    var vP = ((row.cost - row.discount_value) / (total_cost - total_discount_value)) * 100
    var allocate_the_discount = Math.round((vP * dis) / 100)
    console.log("Allocate Discount = " + allocate_the_discount)
    if (data.length == (index + 1)) {
      allocate_the_discount = dis - calculating_discount
    }
    calculating_discount = calculating_discount + allocate_the_discount
    console.log("Calculated Discount = " + calculating_discount)
    console.log("Test Name = " + row.title)
    console.log("Original Cost  = " + row.cost)
    console.log("Discount Value = " + row.discount_value)
    if (parseInt(row.cost) - parseInt(row.discount_value) == 0) {
      return row
    }
    temp_net_cost = (parseInt(row.cost) - parseInt(row.discount_value)) - allocate_the_discount

    console.log("Net Cost = " + temp_net_cost)
    index = index + 1
    if (temp_net_cost < 0) {
      counter = counter + 1
      return row

    }

    var net_final_cost = temp_net_cost
    payable = payable + net_final_cost
    var obj = {
      Test_ID: row.Test_ID,
      code: row.code,
      title: row.title,
      description: row.description,
      cost: row.cost,
      discount_percent: row.discount_percent,
      discount_value: row.discount_value,
      special_discount: allocate_the_discount,
      net_cost: net_final_cost
    }

    return obj
  })

  Promise.all(hold).then((values) => {
    if (counter == 0) {
      setData(values)
      setTotalSpecialDiscount(dis)
      setTotalNetCost(payable)
      setDiscount(parseInt((dis / (total_cost - total_discount_value)) * 100))
      setCC([])
      setBalance_Payment(payable)
      setReceived_Payment(0)
    }
  })
}
// yhan tak ye focHandler function hai 

  
  useEffect(() => {

    
    // check Api
    

              
// registerOpenTab FUNCTION

const registerOpenTab = () => {

  let tabsOpen = 1;

  while (localStorage.getItem('openTab' + tabsOpen) !== null) {
  
    tabsOpen++;
  }

  localStorage.setItem('openTab' + tabsOpen, 'open');

  if (localStorage.getItem('openTab2') !== null) {

      window.alert('This application is already running in ' + (tabsOpen - 1) + ' other browser tab(s).')
      localStorage.clear()
      window.location.reload()
      window.close()
      
  }
}


// unregisterOpenTab FUNCTION

const unregisterOpenTab = () => {

  let tabsOpen = 1;

  while (localStorage.getItem('openTab' + tabsOpen) !== null) {
  
    tabsOpen++;
  }

  localStorage.removeItem('openTab' + (tabsOpen - 1));
}


// EVENT LISTENERS
window.addEventListener('load', registerOpenTab);
window.addEventListener('beforeunload', unregisterOpenTab);


    var id = localStorage.getItem('branch_id')
    var name = localStorage.getItem('patient_name')
    var vSearchStr={
      "User_ID" :localStorage.getItem('user_id'),
      "Menu_Option" :"Overall Create Booking"
  }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebCheckUserStatusActiveAccess",{
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify(vSearchStr)
    }).then(res=>res.json()).then((res)=>{
      console.log(res)
      var vSearchStrN={
        "User_ID" :localStorage.getItem('user_id'),
        "Menu_Option" :"Allowed to Book FOC"
    }
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebCheckUserStatusActiveAccess",{
      method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify(vSearchStrN)
    }).then(res=>res.json()).then((res1)=>{
      if(res1[0].Option_Status!="Not Authorized"){
        setfoccheckCond(true)
      }
      else{
        setfoccheckCond(false)
      }
      console.log("check response "+JSON.stringify(res1[0].Option_Status))
    })

      if(res[0].Option_Status=="Authorized"){
        // Opened Shifts
        var obj={
          User_ID:localStorage.getItem('user_id')
        }
        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebUserRunningShiftsCallsList",{
          method:"POST",
          'Content-Type':"application/json",
          body: JSON.stringify(obj)
        }).then(res=>res.json()).then((OpenShifts)=>{
          var shiftBox=[] 
          if(OpenShifts[0].ArrShiftID==undefined)
          {
            return
          }

         JSON.parse(OpenShifts[0]['ArrShiftID']).map((i,index)=>{
           var obj={
            ArrShiftID:JSON.parse(OpenShifts[0]['ArrShiftID'])[index],
            ArrShiftName:JSON.parse(OpenShifts[0]['ArrShiftName'])[index],
            ArrShiftDate:new Date(JSON.parse(OpenShifts[0]['ArrShiftDate'])[index]).getDate()+"-"+(new Date(JSON.parse(OpenShifts[0]['ArrShiftDate'])[index]).getMonth()+1)+"-"+(new Date(JSON.parse(OpenShifts[0]['ArrShiftDate'])[index]).getFullYear()),
            ArrShiftBranch:JSON.parse(OpenShifts[0]['ArrShiftBranch'])[index],
            select: <Button
            variant="contained"
            color="danger"
            className={classes.button}
            onClick={() => {
              // window.location.href="/ShiftClosing"
              // history.push("/ShiftClosing")
              // 2-aug-2024

              // if(localStorage.getItem("org_branch_id")=="0"){
              //   history.push("/PP/ShiftClosing")
              // }
              // else{
              //   // history.push("/ShiftClosing")
              //   history.push("/PP/ShiftClosing")

              // }
              // 7-aug-2024
              history.push("/PP/ShiftClosing")

              localStorage.setItem('branch_id', JSON.parse(OpenShifts[0]['ArrBranchID'])[index]+"")
              localStorage.setItem('branch_name', JSON.parse(OpenShifts[0]['ArrShiftBranch'])[index]+"")
            }}
            >Select
            </Button>
           }
           shiftBox.push(obj)
         })
         console.log("Shift = ")
         console.log(shiftBox)
         setOpenShifts(true)
         setShifts(shiftBox)
        })
        
      }else{
        localStorage.clear()
        window.location.href = "https://invoicing.mpl-labs.pk/"
      }

    })

    setBranch(id)
    var mob_num = localStorage.getItem("patient_mobile")
    setName(name)
    setMobile(mob_num)
    if (localStorage.getItem('user_id') == null || localStorage.getItem('user_id') == undefined) {
      window.location.href = "https://invoicing.mpl-labs.pk/login"
    }
    if (localStorage.getItem('org_branch_id') == null || localStorage.getItem('org_branch_id') == undefined) {
      localStorage.clear()
      window.location.href = "https://invoicing.mpl-labs.pk/login"
    }
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/BookingSalesAgentsCallsUp").then((res) => res.json()).then((response) => {
      console.log("BookingSalesAgentsCallsUp Api call 760 "+JSON.stringify(response))
        // when portal ready for new client ithis condtion is must for unhanging invoice.js  
        // if(response.Result!=false){
        //   setSalesAgentList(response)
        //     var obj = {}
        //     response.map((item) => {
        //       obj[item.Agent_ID] = item.Agent_Name
        //     })
        //     setSalesAgentNameList(obj)
        //   }
        //   })
      setSalesAgentList(response)
        var obj = {}
        response.map((item) => {
          obj[item.Agent_ID] = item.Agent_Name
        })
        setSalesAgentNameList(obj)

      })
      var obj22={
        branch_id:localStorage.getItem("branch_id")
      }
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetPanelsListBranch",
      {
             method:"POST",
      'Content-Type':"application/json",
      body: JSON.stringify(obj22)
      }
      ).then((res) => res.json()).then((response) => {
        response.push({
          Panel_Code: "Normal",
          Panel_Title: "Normal Patient"
        })
        setPanelList(response)
        var tempObjpanel={}
        response.map((obj)=>{
          tempObjpanel[obj.Panel_Code]=obj
        })
        setpanelObj(tempObjpanel)
      })
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_DoctorsListCallsUp").then((res) => res.json()).then((response) => {
        setDoctorList(response)
        var obj = {}
        response.map((item) => {
          obj[item.DoctorID] = item.DoctorName
        })
        setDoctorNameList(obj)

      })






    fetch("https://reports.mpl-labs.pk:8443/4DACTION/BranchesLoadAllfromWebserver").then(res => res.json()).then((response) => {
      setBranches(response)
      var obj = {}
      response.map((item) => {
        obj[item.BranchID] = item.BranchName
      })
      setSelectBranch(obj)
      fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortalGetOnlyTestsLists?vQuery={%22PIN%22:%22%22,%22PhoneNo%22:%22%22,%22PatientName%22:%22%22,%22RegDateFrom%22:%222000-02-01T04:08:27.995Z%22,%22RegDateTo%22:%222020-08-12T04:08:27.995Z%22}&vUID=120720001&vRole=Patient").then((res) => {
        return res.json()
      }).then((response) => {
        console.log("Invoice TestList recive from useEffect 674 :",response)
        //test code ,testId,testName ye sara response me aa rha hai
        setTestList(response)
        var obj = {}
        response.map((test) => {
          obj[test.Test_Name] = test
        })
        console.log("Invoice TestList obj wise recive from useEffect 681 :",obj)

        setTestCallViaName(obj)
      })
    })
    fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebAirlinesNamesListCallingUp").then(res => res.json()).then((response) => {
      setAirlineList(response)
     })
    //  10-july-2024 to merge ccp portal into invoicing portal
    if(localStorage.getItem("org_branch_id")==1){
    setPanel(localStorage.getItem("panel_name"))
    setSelectedValue(localStorage.getItem("panel_code"))
    setRef_By_Name(localStorage.getItem("ref_by_name"))
    setRef_By_Dr(localStorage.getItem("ref_by_id"))
    console.log("check Ref_By_Dr ")
console.log(Ref_By_Dr)
console.log(localStorage.getItem("ref_by_id"))

    }

  }, [])
// ############## useEffect end in this line #################
  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenHist = () => {
    setOpenHist(true);
  };
  function calculateAge(birthday) {
    birthday.setDate(1)
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseGroupTests = () => {
    setOpenGroupTests(false);
  };
  const handleCloseHist = () => {
    setOpenHist(false);
  };
  const handleImport = () => {
    setImportMenu(false);
  };
  const [name, setName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [id, setId] = React.useState("");
  const [date, setDate] = React.useState(new Date())
  // when we click serach button this function is call
  const search_patient = () => {
    console.log(name)
    fetch(
      "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetsPatientsList?vName=" +
      name +
      "&vMobile=" +
      mobile +
      "&vID=" +
      id
    )
      .then((res) => res.json())
      .then((response) => {
        console.log("Invoice search patient res 726 :",response)
        var data = [];
        // response map start
        response.map((item) => {
          console.log(item)
          var obj = {
            Patient_ID: item.Patient_ID,
            Patient_Name: item.Patient_Name,
            MobileNo: item.MobileNo,
            ToAddress: item.ToAddress,
            eMail: item.eMail,
            Age: getAge(new Date(item.Patient_DOB)),
            cnic: item.CNIC,

            select: (
              <Button
                variant="contained"
                color="primary"
                style={{backgroundColor:"#196c91"}}
                className={classes.button}
                onClick={() => {
                  var url = "https://reports.mpl-labs.pk:8443/4DACTION/WebCheckPictureAlreadyExist"
                  var vSearchStr={
                    patient_id: item.Patient_ID
                  }
                  fetch(url, {
                    method: "POST",
                    'Content-Type': "application/json",
                    body: JSON.stringify(vSearchStr)
                  }).then((res) => res.json()).then((response) => {
                    console.log("Invoice Search patient Select button click response 754: ", response)
                    //ye Api just two value chk krti hai ifPictAlreadyExist true or false 
                    setTurkishImageBoolean(response[0].If_PictureAlreadyExist)
                    setTurkishPassportBoolean(response[0].If_PassportImageAlreadyExist)
              
                  })
// when we click on select button populate function call and pass some value in arguments that are  come from response.map
// 19-july-2024
if(localStorage.getItem("org_branch_id")==1){
  Populate(
    item.Patient_ID,
    item.Patient_Name,
    item.ToAddress,
    item.MobileNo,
    localStorage.getItem("panel_name"),
    item.Patient_DOB,
    item.CNIC,
    item.Passport,
    localStorage.getItem("panel_code"),
    item.Entitlement_ID,
    item.Entitlement_Name,
    item.Entitlement_Balance

  );
}
else{
                  Populate(
                    item.Patient_ID,
                    item.Patient_Name,
                    item.ToAddress,
                    item.MobileNo,
                    item.Panel_Name,
                    item.Patient_DOB,
                    item.CNIC,
                    item.Passport,
                    item.Panel_Code,
                    item.Entitlement_ID,
                    item.Entitlement_Name,
                    item.Entitlement_Balance

                  );
                }
                }}
              >
                Select
              </Button>
            ),
          };

          data.push(obj);
        });
        setSearch_table(data);
        handleOpen(true);
      });
  };
  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? "" : "0") + dd,
      (mm > 9 ? "" : "0") + mm,
      this.getFullYear(),
    ].join("/");
  };

  const Call_Patient_History = () => {
    //when we search patient and click select button populate function call and all state are updated also id
    if (id != undefined && id.trim() != "") {
      fetch(
        "https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_PatientHistoryCallsUp/" + id
      )
        .then((res) => res.json())
        .then((result) => {
          console.log("Invoice Patient History function call response of API 809 :",result)
          var the_rows = [];
          if (result.Result == false) {
            alert("Sorry, No Record Found!!")
            return
          }
          result.map((data) => {
            var ds = new Date(data.Booking_DATE).ddmmyyy();
            var time = msToTime(data.Booking_TiME);
            var obj = {
              id: data.Invoice_ID,
              booking_date: ds,
              booking_time: time,
              test_code: data.Test_CODE,
              test_title: data.Test_Name,
              amount: data.Test_Price,
              discount: data.Discount,
              final: data.Final_Price,
              panel:data.Panel_Name
            };
            the_rows.push(obj);
          });
          console.log(the_rows);
          setPatientHistory(the_rows);
          // call a group modal open by this handlerOpenHistory function and display all value that are updated by patientHistory
          handleOpenHist();
        });
    } else {
      alert("Kindly Search Patient First!");
    }
  };
  // when we click search pateint it will give select button and when click select button populate function call
  const Populate = (id, name, salutation, mobile, panel, dob, cnic, passport, panel_code, entitlement_id, entitlement_name, entitlement_balance) => {
    setId(id);
    setToAddress(salutation)
    setName(name);
    var age = new Date(dob);
    setAge(getAge(age));
    setCNIC(cnic)
    setPassport(passport)
    setMobile(mobile);
    setPanel(panel);
    setEntitlement_Id(entitlement_id)
    setEntitlement_Name(entitlement_name)
    setEntitlement_Amount(entitlement_balance)
    if (entitlement_name != "") {
      setDiscount(100)
      setTempDiscount(100)

    }
    setSalesAgent("Select")
    // 19-july-2024
    if(localStorage.getItem("org_branch_id")==1){
    setRef_By_Dr(localStorage.getItem("ref_by_id"))
    
    }
    else{
    setRef_By_Dr("")
    }
  
    setNormalCase(true)
    setRef_No('')
    setData([])
    setTotal_cost(0)
    setTotalSpecialDiscount(0)
    setTotalNetCost(0)
    setBalance_Payment(0)
    setReceived_Payment(0)
    setSelected_Test_Code_List([])
    setCC([])
    setDiscountPrice(0)
    setDiscount(0)
    setTempDiscount(0)
    if (panel_code != "" && entitlement_name == "") {

      handleChange({
        target: {
          value: panel_code
        }
      })

      if (panelObj[panel_code].AllowDiscount) {
        setAllowDiscount(true)
        setColumns([
          { title: 'Code', field: 'code', editable: 'never' },
          { title: 'Title', field: 'title' },
          // { title: 'Title', field: 'GroupTestDetails' },

          { title: 'Description', field: 'description', type: 'numeric', editable: 'never' },
          {
            title: 'Cost', field: 'cost', type: 'numeric', editable: 'never', render: rowData => {
              return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>
            }
          },
          {
            title: 'Discount %', field: 'discount_percent', type: 'numeric', render: rowData => {
              return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent + "%"}</p>
            }
          },
          {
            title: 'Discount Value', field: 'discount_value', type: 'numeric', editable: 'never', render: rowData => {
              return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>
            }
          },
          {
            title: 'Special Discount', field: 'special_discount', type: 'numeric', editable: 'never', render: rowData => {
              return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>
            }
          },
          {
            title: 'Net Cost', field: 'net_cost', type: 'numeric', editable: 'never', render: rowData => {
              return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>
            }
          }
        ])

      } else {
        setAllowDiscount(false)
        setColumns([
          { title: 'Code', field: 'code', editable: 'never' },
          // { title: 'Title', field: 'title'}, 17-aug-2023
          { title: 'Parameter', field: 'GroupTestDetails' },

          { title: 'Description', field: 'description', type: 'numeric', editable: 'never' },
          {
            title: 'Cost', field: 'cost', type: 'numeric', editable: 'never', render: rowData => {
              return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>
            }
          },
          {
            title: 'Discount %', field: 'discount_percent', type: 'numeric', editable: "never", render: rowData => {
              return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent + "%"}</p>
            }
          },
          {
            title: 'Discount Value', field: 'discount_value', type: 'numeric', editable: 'never', render: rowData => {
              return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>
            }
          },
          {
            title: 'Special Discount', field: 'special_discount', type: 'numeric', editable: 'never', render: rowData => {
              return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>
            }
          },
          {
            title: 'Net Cost', field: 'net_cost', type: 'numeric', editable: 'never', render: rowData => {
              return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>
            }
          }
        ])

      }
    }
    handleClose(false);
    // when all state are update then modal false handler true going to flase 4236 pateint selection modal 
  };
  function getAge(dob) {
    var now = new Date();
    var today = new Date(now.getYear(), now.getMonth(), now.getDate());

    var yearNow = now.getYear();
    var monthNow = now.getMonth();
    var dateNow = now.getDate();



    var yearDob = dob.getYear();
    var monthDob = dob.getMonth();
    var dateDob = dob.getDate();
    var age = {};
    var ageString = "";
    var yearString = "";
    var monthString = "";
    var dayString = "";


    var yearAge = yearNow - yearDob;

    if (monthNow >= monthDob)
      var monthAge = monthNow - monthDob;
    else {
      yearAge--;
      var monthAge = 12 + monthNow - monthDob;
    }

    if (dateNow >= dateDob)
      var dateAge = dateNow - dateDob;
    else {
      monthAge--;
      var dateAge = 31 + dateNow - dateDob;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }

    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge
    };

    if (age.years > 1) yearString = " Y";
    else yearString = " year";
    if (age.months > 1) monthString = " M";
    else monthString = " month";
    if (age.days > 1) dayString = " D";
    else dayString = " day";


    if ((age.years > 0) && (age.months > 0) && (age.days > 0))
      ageString = age.years + yearString + ", " + age.months + monthString + "," + age.days + dayString + " old.";
    else if ((age.years == 0) && (age.months == 0) && (age.days > 0))
      ageString = "Only " + age.days + dayString + " old!";
    else if ((age.years > 0) && (age.months == 0) && (age.days == 0))
      ageString = age.years + yearString + " old. Happy Birthday!!";
    else if ((age.years > 0) && (age.months > 0) && (age.days == 0))
      ageString = age.years + yearString + " and " + age.months + monthString + " old.";
    else if ((age.years == 0) && (age.months > 0) && (age.days > 0))
      ageString = age.months + monthString + " and " + age.days + dayString + " old.";
    else if ((age.years > 0) && (age.months == 0) && (age.days > 0))
      ageString = age.years + yearString + " and " + age.days + dayString + " old.";
    else if ((age.years == 0) && (age.months > 0) && (age.days == 0))
      ageString = age.months + monthString + " old.";
    else ageString = "Oops! Could not calculate age!";

    return ageString;
  }

  return (
    <div className={classes.root}>
      <form noValidate autoComplete="off">
        <div style={{
          margin: 'auto',
          padding: 10


        }}>

          {
            localStorage.getItem('org_branch_id') == '0' ?
              <FormControl variant="filled" className={classes.formControl} style={{ width: "30em" }} >
                <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={branch}
                  onChange={(e) => {
                    var result = window.confirm('Are you Sure ?. Your want to change Branch?')
                    if (result) {
                      setBranch(e.target.value)
                      //set local storage value
                      localStorage.setItem('branch_id', e.target.value)
                      localStorage.setItem('branch_name', selectBranch[e.target.value])
                      var obj22={
                        branch_id:e.target.value+""
                      }
                      fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebPortal_GetPanelsListBranch",
                      {
                             method:"POST",
                      'Content-Type':"application/json",
                      body: JSON.stringify(obj22)
                      }
                      ).then((res) => res.json()).then((response) => {
                        response.push({
                          Panel_Code: "Normal",
                          Panel_Title: "Normal Patient"
                        })
                        setPanelList(response)
                          //set the panelList array with this object
                      })
                      window.location.reload()
                    }

                  }}
                >
                  <MenuItem value={"0"} disabled>Select</MenuItem>
                  {
                    branches.map((item) => {
                      return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>

                    })
                  }
                </Select>
              </FormControl>
              : null
          }
          {/* 20-jan */}
        
              {/* <FormControlLabel
            style={{marginLeft:"2%"}}
                control={<Checkbox checked={foccheck} onChange={(e) => {
                  if(e.target.checked==true){
                    FOChandler()
                  }
                  
                  setfoccheck(e.target.checked)
                  

                }} />}
                label="Free Of Cost (FOC)"
              /> */}

          <Button
            variant="contained"
            color="secondary"
            style={{
              textAlign: 'center',
              fontSize: '1em',
              height: "2em",
              float: 'right',
              padding: 10
            }}
            onClick={() => {
              setImportMenu(true)
              // alert(foccheck)
              localStorage.setItem('data', "")
            }}
          >
            Import
                </Button>
        </div>
        <div>


        </div>
        <div style={{
          textAlign: 'center'
        }}><b>Search Patients using Patient ID, Patient Name & Mobile Number</b></div>
        <div style={{ display: "-webkit-inline-box", width: "100%" }}>
          <form onSubmit={(e) => {
            e.preventDefault();
            search_patient()
          }} >
            <TextField
              id="outlined-required"
              label="Patient ID"
              value={id}
              onChange={(e) => {
                setId(e.target.value);
              }}
              variant="outlined"
            />
            <input type="submit" style={{
              position: 'absolute',
              left: '-9999px'
            }} />
            <TextField
              style={{
                width: "5em"
              }}
              id="outlined-required"
              value={ToAddress}
              variant="outlined"
            />
            <TextField
              id="outlined-required"
              label="Patient Name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="outlined"
            />
            <TextField
              id="outlined-required"
              value={mobile}
              onChange={(e) => {
                setMobile(e.target.value);
              }}
              label="Patient Mobile"
              variant="outlined"
            />
          </form>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" }}
            onClick={() => {     
                search_patient();     
            }}
            className={classes.button}
          >
            Search
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" }}
            onClick={() => {
              setPanel("");
              setId("");
              setMobile("");
              setName("");
              setAge("");
              setCNIC("")
              setToAddress("");
              setPassport("")
              setEntitlement_Name("")
              setEntitlement_Id("")
              setEntitlement_Amount("")
              setData([])
              setDiscount(0)
              setTotal_cost(0)
              setTotalSpecialDiscount(0)
              setTotalNetCost(0)
              setBalance_Payment(0)
              setReceived_Payment(0)
              setSelected_Test_Code_List([])
              setCC([])


            }}
            // when we click the clear button all state are going to be empty
            className={classes.button}
          >
            Clear
          </Button>
          <div style={{
            marginLeft: '5em',
            marginTop: "1em"
          }}>

            <Button
              variant="contained"
              color="primary"
           
              style={{
                height: '100%',
                backgroundColor:"#196c91"
              }}
              onClick={() => {
                Call_Patient_History()
              }}
            >Patient History</Button>
          </div>
        </div>

        <div>
          {
            ScanImage != "" ?
              <div style={{
                float: 'right',
                position: 'relative'
              }}>
                <img id="scanImage" src={ScanImage} alt="Scan Image" width="250px" height="300px" style={{
                  margin: "auto"
                }} />
              </div>
              : null
          }
   
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91"}}

          >
            <b>Patient Docs as Image</b>
            <input type="file" variant="contained"
              color="primary"
              onChange={(e) => { handleScanImage(e) }}
              id="ScanImage" name="img" accept="image/*"
              className={classes.button}

              aria-label="Attach PDF"
            />
          </Button>
          
          {
            !pdfScanFlag
            ?
            <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91"}}

          >
            <b>Patient Docs as PDF</b>
            <input type="file" variant="contained"
              color="primary"
              onChange={(e) => { handleScanPDF(e) }}
              id="ScanPDF" name="pdf" accept="application/pdf"
              className={classes.button}
              aria-label="Attach PDF"
            />
          </Button>
          :
          null
          }
{/* how to show base64 converted pdf  */}
{/* {ScanPDF&&<iframe src={ScanPDF}></iframe>} */}
         
         
         
         {
           pdfScanFlag ?
           <>
            <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            style={{backgroundColor:"#196c91"}}

            onClick={async()=>{
              try{
                if(document.getElementById('response').innerHTML==""){
                  
                }else{
                  setScanPDF(document.getElementById('response').innerHTML)
                }
            }
            catch(e){
              console.log(e)
              alert("Scan File First!!")
            }
            }}
          >
            <b>{ScanPDF=="" ? "Load Scan PDF File" : "Loaded SCAN PDF"}</b>
          
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91"}}

            onClick={async()=>{
              window.scanToLocalDisk()
            }}
          >
            <b>Scan PDF</b>
            <div id="response" hidden={true} ></div>
          </Button>
          </>
          :
          null
         }
     
       
     <Button
            variant="contained"
            color="primary"
            className={classes.button}
            style={{backgroundColor:"#196c91"}}

            >
 <FormControlLabel
              control={<Checkbox checked={pdfScanFlag} onChange={(event)=>{
                setpdfScanFlag(event.target.checked)
              }} name="Scan PDF" />}
              label="Scan PDF"
            />
            </Button>

        </div>

        <div>
          <TextField
            required
            id="outlined-required"
            label="Patient Age"
            value={age}

            variant="outlined"
          />
          <TextField
            required
            id="outlined-required"
            label="CNIC"
            value={CNIC}

            variant="outlined"
          />
          <TextField
            required
            id="outlined-required"
            label="Passport"
            value={passport}

            variant="outlined"
          />
          <TextField
            required
            id="outlined-required"
            label="Entitlement Name"
            value={Entitlement_Name}

            variant="outlined"
          />



        </div>
        <div style={{
          marginLeft:10,
          // backgroundColor:"red"
        }}>
        <FormControl component="fieldset">
      <FormLabel component="legend">Nationality</FormLabel>
      <RadioGroup aria-label="Nationality" name="Nationality" value={National} onChange={(e)=>{
        
        setNational(e.target.value)
      }}>
        <FormControlLabel value="true" control={<Radio />} label="Pakistani" />
        <FormControlLabel value="false" control={<Radio />} label="Other than Pakistani (Foreigner)" />
      </RadioGroup>
      </FormControl>
        </div>
        <div style={{
          display:"flex",
          flexDirection:'row'
        }}>
          {/* when we click Gerry panel category then this value show */}
        {
          !turkishImageBoolean &&  (airlineType=="GERRY")
            ?
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: "center", width: "40%", margin: "auto" }}>
              <h3><b>Registration Image</b></h3>
              <br />
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
              >
                <input type="file" variant="contained"
                  color="primary"
                  onChange={(e) => { handleFileReadImage(e) }}
                  id="PatientImage" name="PatientImage" accept="image/*"
                  className={classes.button}

                  aria-label="Attach PDF"
                />
                Registration Photo
              </Button>
              {
                PatientImage != "" ?
                  <img id="PatientImagePreview" src={PatientImage} alt="Patient Form" width="250px" height="300px" style={{
                    margin: "auto"
                  }} />
                  : null
              }
            </div>
            : null
        }
         {
            !turkishPassportBoolean &&  (airlineType=="KSA" ||  airlineType=="OTHER" || airlineType=="GERRY")
            ?
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: "center", width: "40%", margin: "auto" }}>
             <h3> <b >Passport Image</b>  </h3>
              <br />
              <Button
                variant="contained"
                style={{
                  color:"white",
                  backgroundColor:"green"
                }}
                className={classes.button}
              >
                <input type="file" variant="contained"
                 style={{
                  color:"white",
                  backgroundColor:"green"
                }}
                 onChange={(e) => { handleFileReadPassportImage(e) }}
                  id="PassportImage" name="PassportImage" accept="image/*"
                  className={classes.button}

                  aria-label="Attach PDF"
                />
                Passport
              </Button>
              {
                PassportImage != "" ?
                  <img id="PassportImagePreview" src={PassportImage} alt="Passport Form" width="250px" height="300px" style={{
                    margin: "auto"
                  }} />
                  : null
              }
            </div>
            : null
        }
          
         

        </div>
        {
            airlineType=="KSA" ||  airlineType=="OTHER" ||  airlineType=="GERRY"
            ?
        <div style={{ display: 'flex', flexDirection: 'column', textAlign: "center", width: "30%", margin: "auto" }}>
             <h3> <b >Sampling Picture</b>  </h3>
              <br />
              <Button
                variant="contained"
                color="secondary"

                className={classes.button}
              >
                <input type="file" variant="contained"
                  color="secondary"
                  onChange={(e) => { handleFileReadSamplingPicture(e) }}
                  id="SamplingPicture" name="SamplingPicture" accept="image/*"
                  className={classes.button}

                  aria-label="Attach PDF"
                />
                Sampling Picture
              </Button>
              {
                SamplingPicture != "" ?
                  <img id="SamplingPicturePreview" src={SamplingPicture} alt="Sampling Picture"  width="300px" height="400px" style={{
                    margin: "auto"
                  }} />
                  : null
              }
            </div>
            :
            null
           }
        
        {
          localStorage.getItem('org_branch_id') == '0'
            ?


            <>
              <div style={{
                display: 'flex',
                flexDirection: "row",
                justifyContent: 'space-between'
              }} >
                

{
                  // 15-feb-2024
  // localStorage.getItem('org_branch_id') == '0' && localStorage.getItem("franchise")!="true"

  (localStorage.getItem('org_branch_id') == '0' && localStorage.getItem("franchise")!="true")|| localStorage.getItem("name")=="najam"
  ?
<Autocomplete
                  id="country-select-demo"
                  style={{ width: "15em", paddingRight: "1em" }}
                  options={salesAgentList}
                  disabled={Entitlement_Name.trim() != ""}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      setSalesAgent(newValue.Agent_ID)
                    } else {
                      setSalesAgent("Select")
                    }
                  }}
                  getOptionLabel={(option) => option.Agent_Name}
                  renderOption={(option) => <React.Fragment>{option.Agent_Name}</React.Fragment>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a Sales Agent"
                      variant="outlined"
                      style={{
                        width: "15em"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                :
                null
}
                


                {
                  panel != '' ?
                    <>
                      <TextField
                        required
                        id="outlined-required"
                        label="Default Panel"
                        value={panel}

                        variant="outlined"
                      />

                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          height: '100%',
                          marginTop: "1em",
                          backgroundColor:"#196c91"
                        }}
                        onClick={() => {
                          setPanel("")
                          setAllowDiscount(false)
                          handleChange({
                            target: {
                              value: "Normal"
                            }
                          })
                          setColumns([
                            { title: 'Code', field: 'code', editable: 'never' },
                            { title: 'Title', field: 'title' },
                            { title: 'Description', field: 'description', type: 'numeric', editable: 'never' },
                            {
                              title: 'Cost', field: 'cost', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>
                              }
                            },
                            {
                              title: 'Discount %', field: 'discount_percent', type: 'numeric', editable: selectedValue.includes('Normal') ? "onUpdate" : "never", render: rowData => {
                                return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent + "%"}</p>
                              }
                            },
                            {
                              title: 'Discount Value', field: 'discount_value', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>
                              }
                            },
                            {
                              title: 'Special Discount', field: 'special_discount', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>
                              }
                            },
                            {
                              title: 'Net Cost', field: 'net_cost', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>
                              }
                            }
                          ])

                        }}
                      >Change Panel</Button>
                    </>
                    :
                    <>

                  
                    <Autocomplete
                      id="country-select"
                      options={panelList}
                      classes={{
                        option: classes.option
                      }}
                      autoHighlight
                      onChange={(event, newValue) => {
                        if (newValue != null) {

                          if(newValue.If_CreditPanel){
                            setpanelType("Credit")
                          }else{
                            setpanelType("Cash")
                          }
                          handleChange({
                            target: {
                              value: newValue.Panel_Code
                            }
                          })
                          if (newValue.AllowDiscount) {
                            setAllowDiscount(true)
                            setColumns([
                              { title: 'Code', field: 'code', editable: 'never' },
                              { title: 'Title', field: 'title'},
                              { title: 'Description', field: 'description', type: 'numeric', editable: 'never' },
                              {
                                title: 'Cost', field: 'cost', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>
                                }
                              },
                              {
                                title: 'Discount %', field: 'discount_percent', type: 'numeric', render: rowData => {
                                  return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent + "%"}</p>
                                }
                              },
                              {
                                title: 'Discount Value', field: 'discount_value', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>
                                }
                              },
                              {
                                title: 'Special Discount', field: 'special_discount', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>
                                }
                              },
                              {
                                title: 'Net Cost', field: 'net_cost', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>
                                }
                              }
                            ])
                          } else {
                            setAllowDiscount(false)
                            setColumns([
                              { title: 'Code', field: 'code', editable: 'never' },
                              { title: 'Title', field: 'title'},
                              { title: 'Description', field: 'description', type: 'numeric', editable: 'never' },
                              {
                                title: 'Cost', field: 'cost', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>
                                }
                              },
                              {
                                title: 'Discount %', field: 'discount_percent', type: 'numeric', editable: "never", render: rowData => {
                                  return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent + "%"}</p>
                                }
                              },
                              {
                                title: 'Discount Value', field: 'discount_value', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>
                                }
                              },
                              {
                                title: 'Special Discount', field: 'special_discount', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>
                                }
                              },
                              {
                                title: 'Net Cost', field: 'net_cost', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>
                                }
                              }
                            ])
                          }


                        } else {
                          setAllowDiscount(false)
                          setpanelType("")
                          handleChange({
                            target: {
                              value: "Normal"
                            }
                          })
                          
                          setColumns([
                            { title: 'Code', field: 'code', editable: 'never' },
                            { title: 'Title', field: 'title' },
                            { title: 'Description', field: 'description', type: 'numeric', editable: 'never' },
                            {
                              title: 'Cost', field: 'cost', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>
                              }
                            },
                            {
                              title: 'Discount %', field: 'discount_percent', type: 'numeric', editable: selectedValue.includes('Normal') ? "onUpdate" : "never", render: rowData => {
                                return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent + "%"}</p>
                              }
                            },
                            {
                              title: 'Discount Value', field: 'discount_value', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>
                              }
                            },
                            {
                              title: 'Special Discount', field: 'special_discount', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>
                              }
                            },
                            {
                              title: 'Net Cost', field: 'net_cost', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>
                              }
                            }
                          ])
                        }
                      }}
                      getOptionLabel={(option) => option.Panel_Title}
                      style={{
                        width: "20em"
                      }}
                      renderOption={(option) => <React.Fragment>{option.Panel_Title}</React.Fragment>}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          style={{
                            width: "20em"
                          }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password" // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                    </>
                }
                <Autocomplete
                  id="Ref_By_Doctor"

                  options={DoctorList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.DoctorName + ""}
                  style={{
                    width: "15em",
                    marginRight: '1em'
                  }}
                  renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
                  onChange={(event, newValue) => {

                    if (newValue != null) {

                      setRef_By_Dr(newValue.DoctorID)
                      console.log("Ref by Doctor self"+newValue.DoctorID)
                      setRef_No('')
                    } else {
                      setRef_By_Dr('')
                      setRef_No('')
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ref By Doctor"
                      variant="outlined"
                      style={{
                        width: "15em"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />

              </div>
              {
               //5-april-2023 sir ne kaha tha jab ref by doctor pe self select kren to refrence number gyb ho jata hai wo gyb ni hona chahaye
                // Ref_By_Dr == "" || Ref_By_Dr == 430
                Ref_By_Dr == "" 

                  ?
                  null
                  :
                  <div
                    style={{
                      float: 'right'
                    }}
                  >
                    <TextField
                      required
                      style={{
                        width: "20em"
                      }}
                      value={Ref_No}
                      onChange={(e) => {
                        setRef_No(e.target.value)
                      }}
                      id="outlined-required"
                      label="Reference No"
                      variant="outlined"
                    />
                  </div>
              }
            </>
            :
            <div
            style={
              {
                display: 'flex',
                flexDirection: "row",
                justifyContent: 'space-evenly'
              }
            }
            >
                {
                  // 15-feb-2024
  // localStorage.getItem('org_branch_id') == '0' && localStorage.getItem("franchise")!="true"
  (localStorage.getItem('org_branch_id') == '0' && localStorage.getItem("franchise")!="true")|| localStorage.getItem("name")=="najam"

  ?

                <Autocomplete
                  id="country-select-demo"
                  style={{ width: "15em", paddingRight: "1em" }}
                  options={salesAgentList}
                  disabled={Entitlement_Name.trim() != ""}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      setSalesAgent(newValue.Agent_ID)
                    } else {
                      setSalesAgent("Select")
                    }
                  }}
                  getOptionLabel={(option) => option.Agent_Name}
                  renderOption={(option) => <React.Fragment>{option.Agent_Name}</React.Fragment>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a Sales Agent"
                      variant="outlined"
                      style={{
                        width: "15em"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                :
                null
                }
             {
                  panel != '' ?
                    <>
                      <TextField
                        required
                        id="outlined-required"
                        label="Default Panel"
                        value={panel}

                        variant="outlined"
                      />
{/* 10-july-2024 */}
                      {/* <Button
                        variant="contained"
                        color="primary"
                        style={{
                          height: '100%',
                          marginTop: "1em"
                        }}
                        onClick={() => {
                          setPanel("")
                          setAllowDiscount(false)
                          handleChange({
                            target: {
                              value: "Normal"
                            }
                          })
                          setColumns([
                            { title: 'Code', field: 'code', editable: 'never' },
                            { title: 'Title', field: 'title' },
                            { title: 'Description', field: 'description', type: 'numeric', editable: 'never' },
                            {
                              title: 'Cost', field: 'cost', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>
                              }
                            },
                            {
                              title: 'Discount %', field: 'discount_percent', type: 'numeric', editable: selectedValue.includes('Normal') ? "onUpdate" : "never", render: rowData => {
                                return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent + "%"}</p>
                              }
                            },
                            {
                              title: 'Discount Value', field: 'discount_value', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>
                              }
                            },
                            {
                              title: 'Special Discount', field: 'special_discount', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>
                              }
                            },
                            {
                              title: 'Net Cost', field: 'net_cost', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>
                              }
                            }
                          ])

                        }}
                      >Change Panel</Button> */}
                    </>
                    :
                    <Autocomplete
                      id="country-select"
                      options={panelList}
                      classes={{
                        option: classes.option
                      }}
                      autoHighlight
                      onChange={(event, newValue) => {
                        if (newValue != null) {

                          handleChange({
                            target: {
                              value: newValue.Panel_Code
                            }
                          })
                          if (newValue.AllowDiscount) {
                            setAllowDiscount(true)
                            setColumns([
                              { title: 'Code', field: 'code', editable: 'never' },
                              { title: 'Title', field: 'title'},
                              { title: 'Description', field: 'description', type: 'numeric', editable: 'never' },
                              {
                                title: 'Cost', field: 'cost', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>
                                }
                              },
                              {
                                title: 'Discount %', field: 'discount_percent', type: 'numeric', render: rowData => {
                                  return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent + "%"}</p>
                                }
                              },
                              {
                                title: 'Discount Value', field: 'discount_value', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>
                                }
                              },
                              {
                                title: 'Special Discount', field: 'special_discount', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>
                                }
                              },
                              {
                                title: 'Net Cost', field: 'net_cost', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>
                                }
                              }
                            ])
                          } else {
                            setAllowDiscount(false)
                            setColumns([
                              { title: 'Code', field: 'code', editable: 'never' },
                              { title: 'Title', field: 'title'},
                              { title: 'Description', field: 'description', type: 'numeric', editable: 'never' },
                              {
                                title: 'Cost', field: 'cost', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>
                                }
                              },
                              {
                                title: 'Discount %', field: 'discount_percent', type: 'numeric', editable: "never", render: rowData => {
                                  return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent + "%"}</p>
                                }
                              },
                              {
                                title: 'Discount Value', field: 'discount_value', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>
                                }
                              },
                              {
                                title: 'Special Discount', field: 'special_discount', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>
                                }
                              },
                              {
                                title: 'Net Cost', field: 'net_cost', type: 'numeric', editable: 'never', render: rowData => {
                                  return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>
                                }
                              }
                            ])
                          }


                        } else {
                          setAllowDiscount(false)
                          handleChange({
                            target: {
                              value: "Normal"
                            }
                          })
                          setColumns([
                            { title: 'Code', field: 'code', editable: 'never' },
                            { title: 'Title', field: 'title' },
                            { title: 'Description', field: 'description', type: 'numeric', editable: 'never' },
                            {
                              title: 'Cost', field: 'cost', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>
                              }
                            },
                            {
                              title: 'Discount %', field: 'discount_percent', type: 'numeric', editable: selectedValue.includes('Normal') ? "onUpdate" : "never", render: rowData => {
                                return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent + "%"}</p>
                              }
                            },
                            {
                              title: 'Discount Value', field: 'discount_value', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>
                              }
                            },
                            {
                              title: 'Special Discount', field: 'special_discount', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>
                              }
                            },
                            {
                              title: 'Net Cost', field: 'net_cost', type: 'numeric', editable: 'never', render: rowData => {
                                return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>
                              }
                            }
                          ])
                        }
                      }}
                      getOptionLabel={(option) => option.Panel_Title}
                      style={{
                        width: "20em"
                      }}
                      renderOption={(option) => <React.Fragment>{option.Panel_Title}</React.Fragment>}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Choose a Panel"
                          variant="outlined"
                          style={{
                            width: "20em"
                          }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password" // disable autocomplete and autofill
                          }}
                        />
                      )}
                    />
                }
                {/* 10-july-2024 */}
                {localStorage.getItem("org_branch_id")==1?
                <TextField
              required
              id="outlined-required"
              label="Ref By"
              value={Ref_By_Name}

              variant="outlined"
            />:
                <Autocomplete
                  id="Ref_By_Doctor"

                  options={DoctorList}
                  classes={{
                    option: classes.option
                  }}
                  autoHighlight
                  getOptionLabel={(option) => option.DoctorName + ""}
                  style={{
                    width: "15em",
                    marginRight: '1em'
                  }}
                  renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
                  onChange={(event, newValue) => {

                    if (newValue != null) {

                      setRef_By_Dr(newValue.DoctorID)
                      setRef_No('')
                    } else {
                      setRef_By_Dr('')
                      setRef_No('')
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ref By Doctor"
                      variant="outlined"
                      style={{
                        width: "15em"
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password" // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
}

              {
                //5-april-2023 sir ne kaha tha jab ref by doctor pe self select kren to refrence number gyb ho jata hai wo gyb ni hona chahaye
                // Ref_By_Dr == "" || Ref_By_Dr == 430
                Ref_By_Dr == "" 

                  ?
                  null
                  :
                  <div
                    style={{
                      float: 'right'
                    }}
                  >
                    <TextField
                      required
                      style={{
                        width: "15em"
                      }}
                      value={Ref_No}
                      onChange={(e) => {
                        setRef_No(e.target.value)
                      }}
                      id="outlined-required"
                      label="Reference No"
                      variant="outlined"
                    />
                  </div>
              }

            </div>
        }
        {
            airlineType=="GERRY"
            ?
            <div style={{ display: 'flex', flexDirection: 'column', textAlign: "center", width: "40%", margin: "auto" }}>

              <b>Consent Form for {selectedValue == "AR-01059" || airline == 'Qatar Airways' ? "Qatar (Optional)" : "Gerry's - Covid19 PCR (Compulsory)"} </b>
              <br />

              <Button
                variant="contained"
                color="primary"
                className={classes.button}
              >
                <input type="file" variant="contained"
                  color="primary"
                  onChange={(e) => { handleFileRead(e) }}
                  id="img" name="img" accept="image/*"
                  className={classes.button}

                  aria-label="Attach PDF"
                />
              </Button>
              {
                consentForm != "" ?
                  <img id="consentFormPreview" src={consentForm} alt="Consent Form" width="250px" height="300px" style={{
                    margin: "auto"
                  }} />
                  : null
              }
            </div>
            : null
        }

        {
          airlineType=="NON-TRAVELLER" ?
            null
            :
            <>
              <div style={{ display: "-webkit-inline-box", width: "100%" }}>
                <TextField
                  required
                  id="outlined-required"
                  label="Ticket No"
                  disabled={airlineType=="NON-TRAVELLER"}
                  value={ticket_no}
                  onChange={(e) => {
                    setTicket_No(e.target.value);
                  }}
                  variant="outlined"
                />

                <TextField
                  required
                  id="outlined-required"
                  label="Flight No"
                  value={flight_no}

                  disabled={airlineType=="NON-TRAVELLER"}
                  onChange={(e) => {
                    setFlight_No(e.target.value);
                  }}
                  variant="outlined"
                />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"

                    disabled={airlineType=="NON-TRAVELLER"}
                    label="Flight Date"
                    value={flight_date}
                    onChange={(e) => {
                      setFlight_Date(e)
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'Flight Date',
                    }}
                  />
                  <KeyboardTimePicker
                    label="Flight Time"
                    placeholder="08:00"
                    mask="__:__"
                    format="HH:mm"
                    disabled={airlineType=="NON-TRAVELLER"}
                    value={flight_time}
                    onChange={date => setFlight_time(date)}
                  />
                </MuiPickersUtilsProvider>
                <FormControl variant="filled" className={classes.formControl} style={{ width: "15em" }} disabled={airlineType=="NON-TRAVELLER"} >
                <InputLabel id="demo-simple-select-filled-label">AirLine</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  value={airline}
                  required={airlineType!="NON-TRAVELLER"}
                  onChange={(e) => {
                    var str=e.target.value
                    console.log("Invoice Select AirLines 2257 :",e.target.value)
                    setAirline(str)
                    if(airlineType=="OTHER" && str.split('|')[2]!="OTHER"){
                      setAirlineType("KSA")
                      }
                    setairlineName(str.split('|')[1])
                    setAirlineOtherFlag(str.split('|')[2]!="OTHER")
                  }}
                >
                   <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          {
           Object.keys(airlineList).length==0
           ?
           null
           :
            Object.keys(JSON.parse(airlineList.ArrayAirline_ID)).map((airline_id,index)=>{

            return <MenuItem value={JSON.parse(airlineList.ArrayAirline_ID)[index]+"|"+JSON.parse(airlineList.ArrayAirline_Name)[index]+"|"+JSON.parse(airlineList.ArrayAirlineOtherTag)[index]} >{JSON.parse(airlineList.ArrayAirline_Name)[index]}</MenuItem>
               

            })
            
          }
           </Select>
          </FormControl>

              </div>
              <div>
                <TextField
                  required
                  id="outlined-required"
                  label="Airport"

                  disabled={airlineType=="NON-TRAVELLER"}
                  value={airport}
                  onChange={(e) => {
                    setAirport(e.target.value);
                  }}
                  variant="outlined"
                />
                <TextField
                  required
                  id="outlined-required"
                  label="Destination"
                  disabled={airlineType=="NON-TRAVELLER"}
                  value={destination}
                  onChange={(e) => {
                    setDestination(e.target.value);
                  }}
                  variant="outlined"
                />
                <TextField
                  required
                  id="outlined-required"
                  label="Country"
                  onChange={(e) => {
                    setCountry(e.target.value);
                  }}
                  style={{ width: "30em" }}
                  disabled={airlineType=="NON-TRAVELLER"}
                  value={country}
                  variant="outlined"
                />

              </div>
            </>
        }
        {localStorage.getItem("org_branch_id")==1?<></>:
        <div style={{
          marginLeft: 10,
          textAlign:"left"
        }}>
          <b style={{color:"red"}}>{panelType+" Panel"}</b>
          <br />
          <FormControl component="fieldset">
            <FormLabel component="legend">Category</FormLabel>
            <RadioGroup aria-label="Category" name="Category" value={airlineType} onChange={(e) => {

              setAirlineType(e.target.value)
              if(e.target.value=="NON-TRAVELLER"){
                handleChange({
                  target: {
                    value: "Normal"
                  }
                })
              }else{
                setOtherPanelCase(false)
              }
            
            }}>
              <FormControlLabel value="KSA" control={<Radio />} label="KSA" />
              <FormControlLabel value="GERRY" control={<Radio />} label="GERRY" />
              <FormControlLabel value="OTHER" disabled={airlineOherFlag} control={<Radio />} label="OTHER" />
              <FormControlLabel value="NON-TRAVELLER" control={<Radio />} label="NON-TRAVELLER / Normal Patient" />
            </RadioGroup>
          </FormControl>
        </div>
}
        <div style={{
          flexDirection:"row",
          display:"flex",
          // background:"red"
        }}>
          <Autocomplete
            id="Lab Test Selection"

            options={TestList}
            classes={{
              option: classes.option
            }}
            autoHighlight
           // optionTestCode +OptionTestName ye dono list me show ho rhy hn third es  testName hai TestList me 
            getOptionLabel={(option) => option.Test_Code + " , " + option.Test_Name}
            style={{
              width: "35em",
              marginRight: '1em',
              marginTop: "1em"
            }}
            renderOption={(option) => <React.Fragment>{option.Test_Code + " , " + option.Test_Name}</React.Fragment>}
            onChange={(event, newValue) => {

              if (newValue != null) {
                // var testDetail=[]
console.log("OnChange New Value "+JSON.stringify(newValue))
console.log("Selected Test Code List "+JSON.stringify(Selected_Test_Code_List))

                
                if (Selected_Test_Code_List.includes(newValue.Test_Code)) {
                  alert("Already Test Added!!")
                  return
                }
                console.log("Invoice Lab test selection 2383 ",selectedValue)
                var panel_id = selectedValue
                if (panel_id == "Normal") {
                  panel_id = ""
                }
  //               var objN={
  //                  test_id:newValue.Test_ID
  //                 }
  //                 console.log("New Api Test In 2414 ######################## "+JSON.stringify(objN))

  //               fetch("https://reports.mpl-labs.pk:8443/4DACTION/TestS_AUX_LinesCallsWebServer", {
  //     method:"POST",
  //     body:JSON.stringify(objN)
  // }).then(res=>res.json()).then((response)=>{
  //                   console.log("New Api Test In 2414 ######################## "+ JSON.stringify(response))
                    
  //                 { response.map((row) => {
  //                   console.log("map response"+JSON.stringify(row))
  //                   testDetail.push(row.test_title)

  //                 })}
                 






  // })
  // if(testDetail!=null){
  //   var testDetailString=testDetail.toString()
  // }else{
  //   var testDetailString="null"

  // }
  
  // console.log("New Api Test In 2423 ######################## "+testDetail)
  // console.log("New Api Test In 2425 ######################## "+testDetailString)
                // var objN={
                //   // test_id: newValue.Test_ID
                //   test_id:"UR-001-T-0005"
                // }

                // var vSearchStrN = JSON.stringify(objN)
                // console.log("New Api Test parameter send to API In 2406 ######################## "+vSearchStrN)

                // var url = "https://reports.mpl-labs.pk:8443/4DACTION/TestS_AUX_LinesCallsWebServer"
                
                // fetch(url, {
                //   method: "POST",
                //   'Content-Type': "application/json",
                //   body: vSearchStrN
                //   }).then((res) => res.json()).then(async (responseN) => {
                //     console.log("New Api Test In 2414 ######################## "+responseN)
                //   })

                var obj={
                  airline_id:airline=="" ? "" : parseInt(JSON.parse(airline.split("|")[0])),
                  airline_type:airlineType=="NON-TRAVELLER" ? "" : airlineType,
                  Branch_ID: localStorage.getItem('branch_id'),
                  Panel_ID: selectedValue=="Normal" ? "" : selectedValue ,
                  Test_ID: newValue.Test_ID+""
                }
                console.log("Invoice Lab test selection obj pass to Api 2383 ", obj)
                var vSearchStr = JSON.stringify(obj)
                var url = "https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoiceCallsPriceTest"
          
                fetch(url, {
                        method: "POST",
                        'Content-Type': "application/json",
                        body: vSearchStr
                        }).then((res) => res.json()).then(async (response) => {

                 console.log("Invoice Select Test from list and then call api to get data and display in below table 2409 :",response)
                  // var GroupTestNames=JSON.parse(response.ArrayTestName)
                  // console.log(GroupTestNames)
                
              
                 if(response[0].AllowDiscountPercent<DiscountLimit){
                  setDiscountLimit(response[0].AllowDiscountPercent)
                  setDiscountTestLimit(response[0].Test_Name)
                 }

                  var Apply_The_Discount_Price = 0
                  if (response[0].Test_Price == 0) {
                    alert("Sorry, Test Price is Zero")
                    return
                  }
                  if (Entitlement_Name.trim() != "") {
                    setDiscount(100)
                    setTempDiscount(100)

                    Apply_The_Discount_Price = total_cost + response[0].Test_Price
                  } else {
                    setDiscount(0)
                    setDiscountPrice(0)
                    setTempDiscount(0)
                    Apply_The_Discount_Price = 0
                  }
                  if (Entitlement_Name.trim() != "") {
                    var sum = parseInt(response[0].Test_Price) + total_cost
                    if (sum > parseInt(Entitlement_Amount)) {
                      alert("Entitlement Allocated Amount Reached!!")
                      return
                    }

                  }
                  var date = new Date(response[0].Delivery_Date).ddmmyyy()

                  var desp = date + " AT " + msToTime(response[0].Delivery_Time)
                  if (response[0].Delivery_Time == 0) {
                    desp = response[0].Actual_vs_Offered
                  }

                  var obj1 =
                  {
                    Test_ID: response[0].Test_ID,
                    code: response[0].Test_Code,
                    title: response[0].Test_Name,
                    //desp oper 2445 me date and time hai 
                    description: desp,
                    cost: response[0].Test_Price,
                    discount_percent: 0,
                    group_id:0,
                    group_name:"",
                    discount_value: 0,
                    special_discount: 0,
                    net_cost: response[0].Test_Price,
                    GroupTestDetails:response[0].ArrayTestName,
                    // response[0].ArrayTestName b hain response[0].AarryTestGroup b hn
                    AllowDiscountPercent:response[0].AllowDiscountPercent
                   
                  }
                  console.log(obj1)
                 setSelected_Test_Code_List([...Selected_Test_Code_List, response[0].Test_Code])
                 //jab first time chalta hai data me kch ni hota 2nd dfa jab value dalen ge data me jo first time value us k sth add hoti jaen
                  var all_test = [...data, obj1]
                  var temp_net_cost = 0
                  var payable = 0
                  var counter = 0
                  var index = 0
                  var calculating_discount = 0
                  var temp_discount_value = 0
                  var temp_total_cost = 0
                  //jab first time chalta hai total cost me kch ni hota 2nd dfa jab value dalen ge totalcost me jo first time value us k sth add hoti jae gi
                  var the_total_cost = total_cost + response[0].Test_Price

                  console.log("Invoice allTestArray es me sari value dal rhi hn opr waly obj1 2480 : ",all_test)
                  // all_test pe map start ho rha hai aur hold me value jaen ge
                  var hold = await all_test.map((row) => {
                  //jab first time chalta hai total discount value me kch ni hota 2nd dfa jab value dalen ge total discount value me jo first time value us k sth add hoti jae gi     
                    var vP = ((row.cost - row.discount_value) / (the_total_cost - total_discount_value)) * 100
                    console.log("Percentage of Row = " + vP)
                    var allocate_the_discount = Math.round((vP * Apply_The_Discount_Price) / 100)
                    if (all_test.length == (index + 1)) {
                      allocate_the_discount = Apply_The_Discount_Price - calculating_discount
                    }
                    calculating_discount = calculating_discount + allocate_the_discount
                    if (parseInt(row.cost) - parseInt(row.discount_value) == 0) {
                      return row
                    }
                            // 16-dec-2024

                    temp_net_cost = (parseFloat(row.cost) - parseInt(row.discount_value)) - allocate_the_discount
                    console.log("Net Cost = " + temp_net_cost)
                    index = index + 1
                    if (temp_net_cost < 0) {
                      counter = counter + 1
                      return row

                    }
                    // temp net cost 2493 pe update hua hai yhan loop lga hai sari value update hoti jae gi total cost,disc and payable
                    // var net_final_cost = temp_net_cost
                    // payable = payable + net_final_cost
                    // temp_discount_value = row.discount_value + temp_discount_value
                    // temp_total_cost = temp_total_cost + row.cost
                       //ye tempnetcost her value ka apna hai
                       console.log("##############################2956###############")
                       console.log(temp_net_cost)
                       var net_final_cost = temp_net_cost
                       //ye payable me previous value b add hoti jaen gi 
                    
                       payable = payable +net_final_cost
                        //ye temp_discount_value me previous value b add hoti jaen gi 
                       temp_discount_value = row.discount_value + temp_discount_value
                        //ye temp_total_cost me previous value b add hoti jaen gi 
                       temp_total_cost = temp_total_cost + row.cost
                       console.log(net_final_cost)
                       console.log(payable)
                       console.log(temp_discount_value)
                       console.log( temp_total_cost) 
                       console.log("Group Test Detail 2570"+ row.GroupTestDetails)  
// ye JSON.PARSE KI JGA "" AND [] HTA DETA HAI
                  //  var TempGroupTestDetails= row.GroupTestDetails.replace(/\"/g, '').replace(/\[/g, '').replace(/\]/g, '')
                    //  row alltest waly map se aa rhi
                    var obj = {
// Show detail of test 17-dec-2022 es se phly yhan just row.GroupTestDetails tha
                      GroupTestDetails:DetailShow? row.GroupTestDetails.replace(/\"/g, '').replace(/\[/g, '').replace(/\]/g, ''):null,
                      // GroupTestDetails:testDetail?testDetail:row.GroupTestDetails.replace(/\"/g, '').replace(/\[/g, '').replace(/\]/g, ''),
                     
                      Test_ID: row.Test_ID,
                      AllowDiscountPercent:row.AllowDiscountPercent,
                      code: row.code,
                      title: row.title,
                      description: row.description,
                      cost: row.cost,
                      group_id:row.group_id,
                      group_name:row.group_name,
                      discount_percent: row.discount_percent,
                      discount_value: row.discount_value,
                      special_discount: allocate_the_discount,
                      net_cost: net_final_cost,
                      
                      // detail:JSON.parse(row.GroupTestDetails).length==1?<></>:
                      // <Button  variant="contained" color="primary" key={row.code}  onClick={()=>{
                        
                        // if(row.GroupTestDetails==""){
                        //                               return
                        //                              }
                        // var collection=[]
                        // JSON.parse(row.GroupTestDetails).map((item)=>{
                        //  collection.push({
                        //    name:item
                        //  })
                         
                        // })
                        // setGroupTestInfo(collection)
                        // setOpenGroupTests(true)
                       
                      
                      // }} >Details <  ReceiptIcon /></Button>
                    }

                    return obj
                  })
                  // all_test pe map end ho rha hai aur hold me value save hui hn sari


                  Promise.all(hold).then((values) => {
                    if (counter == 0) {
                      //yhan se data ki value update ho rhi hai hold me jo b value hai wo data me dal den
                      setData(values)
                      setTotalSpecialDiscount(Apply_The_Discount_Price)
                      //ye payable opr update kiya hai 2503
                    // 16-dec-2024
                      setTotalNetCost(Math.round( payable))
                      var temp = parseInt((Apply_The_Discount_Price / (parseInt(temp_total_cost) - parseInt(temp_discount_value))) * 100)
                      if (Apply_The_Discount_Price == 0) {
                        temp = 0
                      }
                      setDiscount(temp)
                      setCC([])
                      //ye temp-total-cost opr update kiya hai 2505
                        //  16-dec-2024
                      setTotal_cost(Math.round(temp_total_cost))
                         //ye temp-discount-value opr update kiya hai 2504
                      setTotalDiscountValue(temp_discount_value)
                        //  16-dec-2024

                      setBalance_Payment(Math.round(payable))
                      setReceived_Payment(0)
                      document.getElementById("Lab Test Selection").value = ""
                      document.getElementById("Lab Test Selection").focus()

                    }
                  })

                })
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Test"
                variant="outlined"
                style={{
                  width: "35em"
                }}
                id={"Test_Selector"}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Button
                variant="contained"
                color="secondary"
                style={{
                  height: '50%',
                  marginTop: "2em"
                }}
                onClick={()=>{
                  setGroupCall(false)
                  fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebGroupNamesCallingUp").then((res) => res.json()).then((response) => {
                    var arr=[]
                    console.log("Invoice when click Call a group first Api response 2605 : ",response)
                    response.map((group)=>{
                      //es arr.push me group ki value plus select ka aik button hai 9 item hn reponse me 9 obj bny ge aur arr ki array me dal deny hn aur map k bahir ye arr groupArry ko pass ho jae gi 2818 aur groupArray ki value true ho jae gi
                      arr.push({
                        // es ...group se jo Api ka response aaya hai jis me groupId,groupName,PanelCode,panelName ye arr me push hoga 
                        ...group,
                        // ye button 2813 me end ho rha hai 
                        select: <Button
                        variant="contained"
                        color="secondary"
                        style={{
                          height: '50%'
                        }}
                        onClick={()=>{
                          var obj={
                            group_id:group.group_id,
                            branch_id:parseInt(localStorage.getItem("branch_id")),
                            panel_code:group.panel_code
                        }
                        fetch("https://reports.mpl-labs.pk:8443/4DACTION/WebGroupTestsPricesCallingUp",
                        {
                               method:"POST",
                        'Content-Type':"application/json",
                        body: JSON.stringify(obj)
                        }
                        ).then((res) => res.json()).then((response) => {
                          console.log("Invoice Select button that are in Call agroup API response :",response)

                          var TestBox=[]

                          JSON.parse(response.ArrayTestsCode).map((row,index)=>{
                            TestBox.push({
                              //value almost wohi hn jo response se aa rhi hn array me hn one by one chahye so that map lgaya and in the base of index hum value utha k textBox me dalty jaen ge
                              //Json.parse json.stringify ka inverse hai hum data JSON.stringify me dety hn us ko dobara json me krny k lye json.parse use krty hn 
                              AllowDiscountPercent:JSON.parse(response.ArrayTestsCode)[index],
                              Delivery_Date:JSON.parse(response.ArrayDelivery_Date)[index],
                              Delivery_Time:JSON.parse(response.ArrayDelivery_Time)[index],
                              ArrayGroupPriceNormal:JSON.parse(response.ArrayGroupPriceNormal)[index],
                              Test_Price:JSON.parse(response.ArrayGroupPriceOffered)[index],
                              // ArrayTestCode pe map lga hai to us ki row me test code he aae ga
                              Test_Code:row,
                              Test_ID:JSON.parse(response.ArrayTestsID)[index],
                              Test_Name:JSON.parse(response.ArrayTestsName)[index],
                              ArrayTestsPricefromDB:JSON.parse(response.ArrayTestsPricefromDB)[index],
                              // response.groupid ye array ni thi that's why ye direct utha li hn also groupName
                              group_id:response.group_id,
                              group_name:response.group_name

                            })

                            
                          })
                          // response.ArrayTestsCode map end
                          console.log("Invoice Select in Call A group TestBox filled after array :",TestBox)
                          var finalBox=[]
                          var testCodeList=[]
                          // Total Discount
                          var Apply_The_Discount_Price = 0
                          // Total cOST
                          var the_total_cost = 0
                          // jo testBox me data aaya hai us pe map lgaya hai total price wgera find krny k lye sab me alag alag price hai 
                          TestBox.map(async(test)=>{
                            //////////////////////////////////////////////////////////////////////////////
                            if(test.AllowDiscountPercent<DiscountLimit){
                              setDiscountLimit(test.AllowDiscountPercent)
                              setDiscountTestLimit(test.Test_Name)
                            }

                             
                              if (test.Test_Price == 0) {
                                alert("Sorry, Test Price is Zero")
                                return
                              }
                              if (Entitlement_Name.trim() != "") {
                                setDiscount(100)
                                setTempDiscount(100)

                                Apply_The_Discount_Price = total_cost + test.Test_Price + Apply_The_Discount_Price
                              } else {
                                setDiscount(0)
                                setDiscountPrice(0)
                                setTempDiscount(0)
                                Apply_The_Discount_Price = 0
                              }
                              if (Entitlement_Name.trim() != "") {
                                var sum = parseInt(test.Test_Price) + total_cost
                                if (sum > parseInt(Entitlement_Amount)) {
                                  alert("Entitlement Allocated Amount Reached!!")
                                  return
                                }

                              }
                              var date = new Date(test.Delivery_Date).ddmmyyy()

                              var desp = date + " AT " + msToTime(test.Delivery_Time)
                              if (test.Delivery_Time == 0) {
                                desp = test.Actual_vs_Offered
                              }

                              var obj1 =
                              {
                                Test_ID: test.Test_ID,
                                code: test.Test_Code,
                                title: test.Test_Name,
                                description: desp,
                                cost: test.Test_Price,
                                discount_percent: 0,
                                discount_value: 0,
                                special_discount: 0,
                                net_cost: test.Test_Price,
                                AllowDiscountPercent:test.AllowDiscountPercent,
                                group_id:test.group_id,
                                group_name:test.group_name,
                                Apply_The_Discount_Price:Apply_The_Discount_Price
                              
                              }
                              finalBox.push(obj1)
                              testCodeList.push(test.Test_Code)
                              the_total_cost=the_total_cost+test.Test_Price
                           
                              /////////////////////////////////////////////////
                          })
                                                //  testBox map end total price find ho gae hai
                          //remaining all same just like select a test

                          console.log("Total Discount")
                          console.log("---------------")
                          console.log(Apply_The_Discount_Price)
                          console.log("---------------")
                            setSelected_Test_Code_List([...Selected_Test_Code_List, ...testCodeList])
                            //data niche update hota hai first tym ye emplty hota hai and finalBox 2720 pe obj1 ki value se update hua hai
                              var all_test = [...data, ...finalBox]
                              var temp_net_cost = 0
                              var payable = 0
                              var counter = 0
                              var index = 0
                              var calculating_discount = 0
                              var temp_discount_value = 0
                              var temp_total_cost = 0
                             

                              var finalBox=[]
                              // alltest me sary select test aa gae hn group waly b us k sth koi aur b add kiye hon to ab final price ,disc etc yhan se find krmy hn 
                              console.log("Invoice Select Call agroup all test value :",all_test)
                             all_test.map((row) => {

                                var vP = ((row.cost - row.discount_value) / (the_total_cost - total_discount_value)) * 100
                                // console.log("Percentage of Row = " + vP)
                                var allocate_the_discount = Math.round((vP * Apply_The_Discount_Price) / 100)
                                if (all_test.length == (index + 1)) {
                                  allocate_the_discount = Apply_The_Discount_Price - calculating_discount
                                }
                                calculating_discount = calculating_discount + allocate_the_discount
                                if ((parseInt(row.cost) - parseInt(row.discount_value) == 0) && row.net_cost!=0.0001) {
                                  return row
                                }
                                
                                temp_net_cost = (parseInt(row.cost) - parseInt(row.discount_value)) - allocate_the_discount
                                index = index + 1
                                // console.log(row.net_cost)
                                if (temp_net_cost < 0 && row.net_cost!=0.0001) {
                                  counter = counter + 1
                                  return row

                                }
                               //ye tempnetcost her value ka apna hai
                                var net_final_cost = temp_net_cost
                                //ye payable me previous value b add hoti jaen gi 
                                payable = payable + net_final_cost
                                 //ye temp_discount_value me previous value b add hoti jaen gi 
                                temp_discount_value = row.discount_value + temp_discount_value
                                 //ye temp_total_cost me previous value b add hoti jaen gi 
                                temp_total_cost = temp_total_cost + row.cost
                                console.log(net_final_cost)
                                console.log(payable)
                                console.log(temp_discount_value)
                                console.log( temp_total_cost)                          

                                var obj = {
                                  Test_ID: row.Test_ID,
                                  AllowDiscountPercent:row.AllowDiscountPercent,
                                  code: row.code,
                                  title: row.title,
                                  description: row.description,
                                  cost: row.cost,
                                  discount_percent: row.discount_percent,
                                  discount_value: row.discount_value,
                                  special_discount: allocate_the_discount,
                                  net_cost: net_final_cost,
                                  group_id:row.group_id,
                                  group_name:row.group_name
                                }

                                finalBox.push(obj)

                                return obj
                              })

                                if (counter == 0) {
                                  setData(finalBox)
                                  setTotalSpecialDiscount(Apply_The_Discount_Price)
                                  setTotalNetCost(payable)
                                  var temp = parseInt((Apply_The_Discount_Price / (parseInt(temp_total_cost) - parseInt(temp_discount_value))) * 100)
                                  if (Apply_The_Discount_Price == 0) {
                                    temp = 0
                                  }
                                  setDiscount(temp)
                                  setCC([])
                                  setTotal_cost(temp_total_cost)
                                  setTotalDiscountValue(temp_discount_value)
                                  setBalance_Payment(payable)
                                  setReceived_Payment(0)
                                  document.getElementById("Lab Test Selection").value = ""
                                  document.getElementById("Lab Test Selection").focus()
                                  setGroupCall(false)
                                // Show detail of test 17-dec-2022
                                  setDetailShow(false)

                                }
                         
                        })


                        }}
                        >Select</Button>

                      })
                    })
                    console.log("Invoice All value added in arr array in call a group test Api :",arr)
                    setGroupList(arr)
                    setGroupCall(true)
                    
                  })

                  
                }}
                >Call a Group</Button>
        </div>
       
        <div>
          <Grid container>
            <Grid xs={12} sm={12} md={12} lg={12}>
              <MaterialTable
                title="Services"
              
                options={{
                  headerStyle: {
                    backgroundColor:"#196c91", // Your desired header background color
                    color: "#FFF",             // Text color for header
                  },
                }}
                columns={columns}
                data={data}
                style={{
                  marginLeft: 10
                }}
                maxBodyHeight={"50em"}
                editable={{
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      setTimeout(async () => {
                        const dataUpdate = [...data];
                        const index = oldData.tableData.id;
                        if (oldData.title != newData.title && (oldData.Test_ID.includes('SRV'))) {
                          dataUpdate[index].title = newData.title;
                          setData([...dataUpdate]);
                        }
                       // Update Require
                        if(newData.discount_percent>newData.AllowDiscountPercent){
                          alert("Discount Limit of "+newData.Test_Name+" = "+newData.AllowDiscountPercent+"%")
                          resolve();
                          return
                        }
                        
                        if (newData.discount_percent != oldData.discount_percent) {
                          if (newData.discount_percent > 100) {

                          } else {
                            var dc_value = (newData.cost / 100) * parseInt(newData.discount_percent)

                            var net_cost = (newData.cost - dc_value) - parseInt(newData.special_discount)

                            if (net_cost < 0) {
                              alert("More Disocunt can't applied!")

                              resolve();
                              return
                            }

                            newData.discount_value = dc_value
                            newData.net_cost = net_cost

                            dataUpdate[index] = newData;
                            console.log("2911"+JSON.stringify(dataUpdate[index]))
                            console.log("2911"+JSON.stringify( newData))
                             setData([...dataUpdate]);
                          }
                        }

                        var cost = 0
                        var discount_value = 0
                        var sp_disc = 0
                        var net_cost = 0
                        var temp = await dataUpdate.map((item) => {
                          console.log("2920"+item)
                          cost = cost + item.cost
                          discount_value = discount_value + item.discount_value
                          sp_disc = sp_disc + item.special_discount
                          return net_cost = net_cost + item.net_cost
                        })
                        Promise.all(temp).then(() => {
                          setTotal_cost(cost)
                          setTotalDiscountValue(discount_value)
                          setTotalSpecialDiscount(sp_disc)
                          // 16-dec-2024
                          setTotalNetCost(Math.round(net_cost))
                          setCC([])
                          // 16-dec-2024

                          setBalance_Payment(Math.round(net_cost))
                          setReceived_Payment(0)
                        })




                        resolve();
                      }, 1000)
                    }),
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(async () => {
                        const dataDelete = [...data];
                        console.log("ye data delete"+JSON.stringify(dataDelete))
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        var ind = Selected_Test_Code_List.indexOf(oldData.Test_Code)
                        Selected_Test_Code_List.splice(ind, 1)

                        if (data.length == 0) {
                          setTotalNetCost(0)
                          setTotalSpecialDiscount(0)
                          setTotal_cost(0)
                          setTotalDiscountValue(0)
                          setDiscount(0)
                          setReceived_Payment(0)
                          setBalance_Payment(0)
                          setCC([])
                        } else {
                          var all_test = [...dataDelete]

                          var temp_net_cost = 0
                          var payable = 0
                          var counter = 0
                          var i = 0
                          var calculating_discount = 0
                          var temp_discount_value = 0
                          var temp_total_cost = 0
                          var the_total_cost = total_cost - oldData.cost
                          var Apply_The_Discount_Price = 0
                          if (Entitlement_Name.trim() != "") {
                            Apply_The_Discount_Price = the_total_cost
                          } else {
                            Apply_The_Discount_Price = 0
                          }
                          var the_total_discount_price = total_discount_value - oldData.discount_value

                          var hold = await all_test.map((row) => {
                            var vP = ((row.cost - row.discount_value) / (the_total_cost - the_total_discount_price)) * 100
                            console.log("Percentage of Row = " + vP)
                            var allocate_the_discount = Math.round((vP * Apply_The_Discount_Price) / 100)
                            if (all_test.length == (i + 1)) {
                              allocate_the_discount = Apply_The_Discount_Price - calculating_discount
                            }
                            calculating_discount = calculating_discount + allocate_the_discount
                            if (parseInt(row.cost) - parseInt(row.discount_value) == 0) {
                              return row
                            }
                            // 16-dec-2024
                            temp_net_cost = (parseFloat(row.cost) - parseInt(row.discount_value)) - allocate_the_discount
                            console.log("Net Cost = " + temp_net_cost)
                            i = i + 1
                            if (temp_net_cost < 0) {
                              counter = counter + 1
                              return row

                            }

                            var net_final_cost = temp_net_cost
                            payable = payable + net_final_cost
                            temp_discount_value = row.discount_value + temp_discount_value
                            temp_total_cost = temp_total_cost + row.cost
                            console.log("################################",row)
                            var obj = {
                              Test_ID: row.Test_ID,
                              code: row.code,
                              title: row.title,
                              description: row.description,
                              cost: row.cost,
                              discount_percent: row.discount_percent,
                              discount_value: row.discount_value,
                              special_discount: allocate_the_discount,
                              net_cost: net_final_cost,
                              //show detail of test 17-dec-2022 ye phly ni thi
                              GroupTestDetails:row.GroupTestDetails,

                               detail:row.detail
                            }

                            return obj
                          })

                          Promise.all(hold).then((values) => {
                            if (counter == 0) {
                              console.log("********************"+JSON.stringify( values[0]))
                              setData(values)
                              setTotalSpecialDiscount(Apply_The_Discount_Price)
                              // 16-dec-2024
                              setTotalNetCost(Math.round(payable))
                              var temp = parseInt((Apply_The_Discount_Price / (parseInt(temp_total_cost) - parseInt(temp_discount_value))) * 100)
                              if (Apply_The_Discount_Price == 0) {
                                temp = 0
                              }
                              setDiscount(temp)
                              setCC([])
                              // 16-dec-2024
                              setTotal_cost(Math.round(temp_total_cost))
                              setTotalDiscountValue(temp_discount_value)
                              // 16-dec-2024

                              setBalance_Payment(Math.round(payable))
                              setReceived_Payment(0)
                            }
                          })
                        }

                        setData([...dataDelete]);

                        resolve();
                      }, 1000)
                    }),
                }}
              />
            </Grid>

          </Grid>

        </div>
        <div>
          <Grid container style={{
            marginTop: 10,
            marginLeft: 10
          }}>

            <Grid xs={3} sm={3} md={3} lg={3} style={
              {
                display: 'flex',
                flexDirection: 'column'
              }
            }>
              <b>Total Cost</b>
              <TextField
                required
                id="outlined-required"
                label=""
                value={total_cost}
                style={{
                  borderRadius: 5
                }}
                variant="outlined"
              />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3} style={
              {
                display: 'flex',
                flexDirection: 'column'
              }
            }>
              <b>Allocated Discount Value</b>
              <TextField
                required
                id="outlined-required"
                style={{
                  borderRadius: 5
                }}
                label=""
                value={total_discount_value}
                variant="outlined"
              />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3} style={
              {
                display: 'flex',
                flexDirection: 'column'
              }
            }>
              <b>Allocated Special Discount</b>
              <TextField
                required
                id="outlined-required"
                label=""
                style={{
                  borderRadius: 5
                }}
                value={total_special_discount}
                variant="outlined"
              />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3} style={
              {
                display: 'flex',
                flexDirection: 'column'
              }
            }>
              <b>Net Payable Value</b>
              <TextField
                required
                id="outlined-required"
                label=""

                style={{
                  borderRadius: 5,
                }}
                value={total_net_cost}
                variant="outlined"
              />
            </Grid>
          </Grid>

        </div>
        <Grid container style={{
          marginLeft: 10
        }}>
          <Grid xs={3} sm={3} md={3} lg={3} style={{
            display: "flex",
            flexDirection: "column"
          }}>
            <b>Patient Comments (Visible on Lab Order)</b>
            <TextareaAutosize
              rowsMax={4}
              style={{
                padding: '0.5em',
                height: '5em',
                marginLeft: "0.6em"
              }}
              value={Comments_for_Patient}
              onChange={(e) => {
                setComments_for_Patient(e.target.value)
              }}
              placeholder="Patient Comments"
            />
          </Grid>
          <Grid xs={3} sm={3} md={3} lg={3} style={{
            display: "flex",
            flexDirection: "column"
          }}>
            <b style={{ color: "red" }}>Remarks (Internal Use)</b>
            <TextareaAutosize
              rowsMax={4}
              style={{
                padding: '0.5em',
                height: '5em',
                marginLeft: "0.6em"
              }}
              value={Invoice_Remarks}
              onChange={(e) => {
                setInvoiceRemarks(e.target.value)
              }}
              placeholder="Remarks"
            />
          </Grid>
          <Grid xs={3} sm={3} md={3} lg={3} >

            <FormGroup style={{
              marginLeft: "5em"
            }}>
              <b>Payment Method</b>
              <FormControlLabel
                control={<Checkbox checked={cash} onChange={(e) => {
                  setCash(e.target.checked)
                  if (e.target.checked) {
                    setCreditCard(false)
                    setDD(false)
                    setCheque(false)
                  }
                }} />}
                label="CASH"
              />
              <FormControlLabel
                control={<Checkbox checked={credit_card} onChange={(e) => {
                  setCreditCard(e.target.checked)
                  if (e.target.checked) {
                    setCash(false)
                    setDD(false)
                    setCheque(false)
                  }
                }} />}
                label="Credit Card"
              />
              <FormControlLabel
                control={<Checkbox checked={dd} onChange={(e) => {
                  setDD(e.target.checked)
                  if (e.target.checked) {
                    setCash(false)
                    setCreditCard(false)
                    setCheque(false)
                  }
                }} />}
                label="Bank Transfer"
              />
              <FormControlLabel
                control={<Checkbox checked={cheque} onChange={(e) => {
                  setCheque(e.target.checked)
                  if (e.target.checked) {
                    setCash(false)
                    setDD(false)
                    setCreditCard(false)
                  }
                }} />}
                label="Cheque"
              />
            </FormGroup>
          </Grid>
          <Grid xs={3} sm={3} md={3} lg={3} style={{
            display: 'flex',
            flexDirection: 'column'
          }}>

            <b>Discount % (line items)</b>
            <span style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "0.5em"
            }}>
              <TextField
                required
                id="outlined-required"
                label=""
                disabled={Entitlement_Name.trim() != ""}
                style={{
                  borderRadius: 5,
                }}

                type="number"
                onChange={(e) => {
                  setTempDiscount(parseInt(e.target.value))
                  setDiscountPrice(0)
                }}
                value={TempDiscount}
                variant="outlined"
              />
              <b style={{
                marginTop: "10%"
              }}>%</b>
              <TextField
                required
                id="outlined-required"
                label=""
                style={{
                  borderRadius: 5,
                }}
                disabled={Entitlement_Name.trim() != ""}
                type="number"
                onChange={(e) => {

                  setDiscountPrice(parseInt(e.target.value))
                  setTempDiscount(0)
                }}
                value={DiscountPrice}
                variant="outlined"
              />
              <b style={{
                marginTop: "10%"
              }}>Rs.</b>
            </span>

            <span style={{
              display: "flex",
              flexDirection: "row"

            }}>
              <TextField
                required
                id="outlined-required"
                // label=""
                title="Applied Discount"
                label="Applied Discount"

                style={{
                  borderRadius: 5,
                  fontSize: 10,
                  fontWeight: 'bold'
                }}
                value={Discount + " %"}
                variant="outlined"
              />


              <Button
                variant="contained"
                color="primary"
                disabled={selectedValue == 'Normal' || AllowDiscount ? false : true}
                style={{
                  height: '50%',
                  marginTop: "1em",
              backgroundColor:"#196c91"

                }}
                onClick={
                  async () => {
                    if (Entitlement_Name != '') {
                      alert("Entitlement gives 100% Discount!!")
                      setDiscount(100)
                      setTempDiscount(100)

                      return
                    }

                    var dis = parseInt(DiscountPrice)
                    // Percentage of Discount
                    
                    if (TempDiscount != 0) {
                      dis = parseInt(((total_cost - total_discount_value) / 100) * TempDiscount)
                      setDiscountPrice(dis)
                    }
                    // Discount Price

                    if (DiscountPrice != 0) {
                      if (DiscountPrice > (total_cost - total_discount_value)) {
                        setDiscountPrice(dis)
                        alert("Discount can't be more than Payable Amount")
                        return
                      }
                    }
                    // Update Received
                    if(TempDiscount>DiscountLimit || parseInt((dis / (total_cost - total_discount_value)) * 100) > DiscountLimit){
                      alert("Discount Limit of Test "+DiscountTestLimit+" = "+DiscountLimit+"%. Please Apply Individual Discount.")
                      setDiscountPrice(dis)
                      setTempDiscount(100)
                      return
                    }
                   

                    // Each Test Price Calculation

                    var temp_net_cost = 0
                    var payable = 0
                    var counter = 0
                    var index = 0
                    var calculating_discount = 0
                    console.log(data)
                    var hold = await data.map((row) => {
                      var vP = ((row.cost - row.discount_value) / (total_cost - total_discount_value)) * 100
                      var allocate_the_discount = Math.round((vP * dis) / 100)
                      console.log("Allocate Discount = " + allocate_the_discount)
                      if (data.length == (index + 1)) {
                        allocate_the_discount = dis - calculating_discount
                      }
                      calculating_discount = calculating_discount + allocate_the_discount
                      console.log("Calculated Discount = " + calculating_discount)
                      console.log("Test Name = " + row.title)
                      console.log("Original Cost  = " + row.cost)
                      console.log("Discount Value = " + row.discount_value)
                      if (parseInt(row.cost) - parseInt(row.discount_value) == 0) {
                        return row
                      }
                      temp_net_cost = (parseInt(row.cost) - parseInt(row.discount_value)) - allocate_the_discount

                      console.log("Net Cost = " + temp_net_cost)
                      index = index + 1
                      if (temp_net_cost < 0) {
                        counter = counter + 1
                        return row

                      }

                      var net_final_cost = temp_net_cost
                      payable = payable + net_final_cost
                      var obj = {
                        Test_ID: row.Test_ID,
                        code: row.code,
                        title: row.title,
                        description: row.description,
                        cost: row.cost,
                        discount_percent: row.discount_percent,
                        discount_value: row.discount_value,
                        special_discount: allocate_the_discount,
                        net_cost: net_final_cost
                      }

                      return obj
                    })

                    Promise.all(hold).then((values) => {
                      if (counter == 0) {
                        setData(values)
                        setTotalSpecialDiscount(dis)
                        setTotalNetCost(payable)
                        setDiscount(parseInt((dis / (total_cost - total_discount_value)) * 100))
                        setCC([])
                        setBalance_Payment(payable)
                        setReceived_Payment(0)
                      }
                    })






                  }
                }>Apply</Button>
            </span>

          </Grid>


        </Grid>
        <div style={{
          marginLeft: 10
        }}>  <h2>Transactions Posting</h2></div>
        <div style={{
          marginLeft: 10,
          display: "flex",
          flexDirection: "row"
        }}>

          <TextField
            required
            id="outlined-required"
            label="Amount"
            style={{
              borderRadius: 5,
            }}
            value={cc_amount}
            type="number"
            onChange={(e) => {
              setCCAmount(e.target.value)
            }}
            variant="outlined"
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date"
              value={cc_date}
              onChange={(e) => {
                setCCDate(e)
                console.log("daaaaaaaaaaaaateeeeeeeee"+e)
              }}
              KeyboardButtonProps={{
                'aria-label': 'Date',
              }}
            />
          </MuiPickersUtilsProvider>
          {
            (cheque || credit_card || dd) ?
              <TextField
                required
                id="outlined-required"
                label="Cheque / Transaction #"
                style={{
                  borderRadius: 5,
                }}
                value={cc_num}
                onChange={(e) => {
                  setCCNum(e.target.value)
                }}
                variant="outlined"
              />
              : null
          }
          <span style={{
            display: 'flex',
            flexDirection: "column",
            marginTop: '-1em'
          }}>
            <b>Remarks</b>
            <TextareaAutosize
              rowsMax={4}
              style={{
                padding: '0.5em',
                height: '3em',
                marginLeft: "0.6em"
              }}
              value={cc_remarks}
              onChange={(e) => {
                setCCRemarks(e.target.value)
              }}
              placeholder="Transaction Remarks"
            /></span>

          <Button
            variant="contained"
            color="primary"
            style={{
              textAlign: 'center',
              fontSize: '1em',
              height: "2em",
              backgroundColor:"#196c91"

            }}
            onClick={() => {
              if (total_net_cost == 0) {
                alert("Invoice Payable is Zero!!")
                return
              }
              if (cc_amount == 0 || cc_amount == "") {
                alert("Kindly Mention Received Amount !!")
                return
              }
              var diff = (total_net_cost - cc_amount) - Received_Payment
              if (diff < 0) {
                alert("Payable Amount Reached!!")
                return
              }
              var receive = parseInt(cc_amount) + parseInt(Received_Payment)
              var bal = parseInt(total_net_cost) - receive
              setReceived_Payment(receive)
              setBalance_Payment(bal)
              var obj = {
                date: cc_date.ddmmyyy(),
                cc: cc_num,
                amount: cc_amount,
                remarks: cc_remarks,
                mode: cash ? "CASH" : cheque ? "CHEQUE" : credit_card ? "CARD" : "DD",
                shift_id: "",
                user_id: localStorage.getItem("user_id"),
                user_name: localStorage.getItem("name")
              }
              setCC([...CC, obj])
              setCCAmount(0)
              setCCRemarks("")
              setCCNum("")
            }}
            className={classes.button}
          >Add</Button>
          {/* 23-jan */}
          {/* 20-jan */}
            {foccheckCond && <FormControlLabel
            style={{marginLeft:"10%"}}
                control={<Checkbox checked={foccheck} onChange={(e) => {
                  //  if(e.target.checked==true){
                  //   FOChandler()
                  // }
                  setfoccheck(e.target.checked)

                }} />}
                label="Free Of Cost (FOC)"
              />}
              {/* <FormControlLabel
            style={{marginLeft:"10%"}}
                control={<Checkbox checked={foccheck} onChange={(e) => {
                   if(e.target.checked==true){
                    // FOChandler()
                  }
                  setfoccheck(e.target.checked)

                }} />}
                label="Free Of Cost (FOC)"
              /> */}

        </div>
        <div>
          <Grid container>

            <Grid xs={6} sm={6} md={6} lg={6}>
              <MaterialTable
                title="Cheque / Card"
                columns={cc_columns}
                data={CC}
                style={{
                  marginLeft: 10
                }}
                editable={{
                  onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                      setTimeout(() => {
                        const dataDelete = [...CC];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        console.log(dataDelete)
                        var receive = parseInt(Received_Payment) - parseInt(oldData.amount)
                        var balance = parseInt(total_net_cost) - receive

                        setReceived_Payment(receive)
                        setBalance_Payment(balance)
                        setCC([...dataDelete]);
                        resolve();
                      }, 1000)
                    }),
                }}
              />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3}></Grid>
            <Grid xs={3} sm={3} md={3} lg={3} style={{
              display: "flex",
              flexDirection: "column"
            }}>
              <TextField
                required
                id="outlined-required"
                label="RECEIVED"
                style={{
                  borderRadius: 5,
                }}
                value={Received_Payment}
                variant="outlined"
              />
              <TextField
                required
                id="outlined-required"
                label="BALANCE"
                style={{
                  borderRadius: 5,
                }}
                // 23-jan
                value={foccheck?"0": Balance_Payment}
                variant="outlined"
              />
              {saveInvoice ?
                <center style={{
                  textAlign: 'center',
                  marginLeft: '2em'
                }}>
                  <ReactLoading type={'spinningBubbles'} color={'black'} height={'10em'} width={'10em'} />
                </center>
                : null}
              <Button
                variant="contained"
                color="primary"
                disabled={saveInvoice}
                style={{
                  textAlign: 'center',
                  width: "14.5em",
                  padding: "1em",
                  height: "4em",
                  marginLeft: "8px",
              backgroundColor:"#196c91"

                }}
                onClick={async () => {
                  setSaveInvoice(true)
                  var branch_id = localStorage.getItem('branch_id')
                  if(branch_id==undefined || branch_id==null){
                    alert("Please Login Again!!!!!")
                    alert("Your Invoice is not saved!!")
                    window.location.reload()
                    setSaveInvoice(false)
                    return
                  }
                  if (branch_id == "0") {
                    alert('Kindly Select Branch!!')

                    setSaveInvoice(false)
                    return
                  }
                 

                  if (name.trim() == '') {
                    alert("Kindly Select Patient")

                    setSaveInvoice(false)
                    return
                  }
                  if (id.trim() == '') {
                    alert("Kindly Select Patient")

                    setSaveInvoice(false)
                    return
                  }
                  // 19-aug-2024 recpetion se msg aaya mobile k bgair invoice save ni ho rhi thi
                  // if (mobile.trim() == '') {
                  //   alert("Kindly Select Patient")

                  //   setSaveInvoice(false)
                  //   return
                  // }
                  if (data.length == 0) {
                    alert("Kindly Select Lab Test")
                    setSaveInvoice(false)
                    return
                  }


                  if (airlineType!="NON-TRAVELLER") {
                    if(National=='true'){
                      if(CNIC.trim()=="" || passport.trim()==""){
                        alert('Failed, CNIC & Passport is compulsory for Passengers')
                        setSaveInvoice(false)
                        return
                      }
                    }else{
                      if(passport.trim()==""){
                        alert('Failed, Passport is compulsory for Passengers')
                        setSaveInvoice(false)
                        return
                      }
                    }
                    if (flight_time == 0) {
                      alert('Kindly Select Flight Time!!')

                      setSaveInvoice(false)
                      return

                    }
                    if (airline.trim() == '') {
                      alert('Invalid AirLine !')

                      setSaveInvoice(false)
                      return
                    }
                    if (ticket_no.trim() == '') {
                      alert('Invalid Ticket Number!')

                      setSaveInvoice(false)
                      return
                    }
                    if (flight_date.getFullYear() == '1900') {
                      alert('Invalid Flight Date!')

                      setSaveInvoice(false)
                      return

                    }
                    if (flight_no.trim() == '') {
                      alert('Invalid Flight Number!')

                      setSaveInvoice(false)
                      return
                    }
                    if (flight_date == 'Invalid Date') {
                      alert('Invalid Flight Date!')

                      setSaveInvoice(false)
                      return
                    }
                    if (flight_time == 'Invalid Date') {
                      alert('Invalid Flight Time!')

                      setSaveInvoice(false)
                      return
                    }
                    if (flight_time == '') {
                      alert('Invalid Flight Time!')

                      setSaveInvoice(false)
                      return
                    }
                    var current_date = new Date()
                    const diffTime = Math.abs(flight_date - current_date);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    if (diffDays > 72) {
                      alert('Flight Date is more than 72 Hours!')

                      setSaveInvoice(false)
                      return
                    }

                    if (airport.trim() == '') {
                      alert('Invalid Airport!')

                      setSaveInvoice(false)
                      return
                    }
                    if (destination.trim() == '') {
                      alert('Invalid Destination!')

                      setSaveInvoice(false)
                      return
                    }
                    flight_date.setHours(15)
                    var user_id = localStorage.getItem('user_id')
                    var branch_id = localStorage.getItem('branch_id')

                    var salesAgent_ID = ""
                    var salesAgent_Name = ""
                    if (salesAgent != "Select") {
                      salesAgent_ID = salesAgent
                      salesAgent_Name = salesAgentNameList[salesAgent_ID]
                    }
                    var DoctorID = ""
                    var DoctorName = ""

                    if (Ref_By_Dr != '') {
                      DoctorID = Ref_By_Dr
                      DoctorName = DoctorNameList[DoctorID]
                    } else {
                      alert("Kindly Select Refer By")
                      setSaveInvoice(false)
                      return
                    }

                    if ( !turkishImageBoolean &&  PatientImage == ""  && airlineType=="GERRY") {
                      alert("Kindly Upload Registration Photo!!")
                      setSaveInvoice(false)
                      return
                    }
                    if (PassportImage == "" && !turkishPassportBoolean) {
                      alert("Kindly Upload Passport Image!!")
                      setSaveInvoice(false)
                      return
                    }
                    if (SamplingPicture == "") {
                      alert("Kindly Upload Sampling Picture !!")
                      setSaveInvoice(false)
                      return
                    }

                    if (airlineType=="GERRY" && consentForm == "") {
                      alert("Kindly Upload Consent Form!!")
                      setSaveInvoice(false)
                      return
                    }
                    
                    var Arr_InV_teSt_ID = []
                    var Arr_InV_CodE = []
                    var Arr_InV_TiTLE = []
                    var Arr_InV_DesCP = []
                    var Arr_InV_LineS_naturE = []
                    var Arr_InV_RatE = []
                    var Arr_InV_DisC_P = []
                    var Arr_InV_DisC_VaLuE = []
                    var Arr_InV_DisC_SP = []
                    var Arr_InV_AMOUNT = []
                    var Arr_InV_REFUND = []
                    var Arr_InV_CANCEL = []
                    var Arr_InV_AuX_reCS_ID = []
                    var Arr_InV_testS_GrouP_ID = []
                    var Arr_InV_testS_GrouP_TiTLE = []
                    var Total_Discount = 0
                    var Total_SpecialDiscount = 0
                    var Total_Amount = 0
                  

                    var invoice_table = await data.map((item) => {
                      console.log(item)
                      Arr_InV_teSt_ID.push(item.Test_ID)
                      Arr_InV_CodE.push(item.code)
                      Arr_InV_TiTLE.push(item.title)
                      Arr_InV_DesCP.push(item.description)
                      Arr_InV_LineS_naturE.push("")
                      Arr_InV_RatE.push(item.cost + "")
                      Arr_InV_DisC_P.push(item.discount_percent + "")
                      Arr_InV_DisC_VaLuE.push(item.discount_value + "")
                      Arr_InV_DisC_SP.push(item.special_discount + "")
                      Arr_InV_AMOUNT.push(item.net_cost + "")
                      Arr_InV_REFUND.push(false)
                      Arr_InV_CANCEL.push(false)
                      Arr_InV_AuX_reCS_ID.push(0)
                      Arr_InV_testS_GrouP_ID.push(item.group_id)
                      Arr_InV_testS_GrouP_TiTLE.push(item.group_name)
                      Total_Discount = Total_Discount + parseInt(item.discount_value)
                      Total_Amount = Total_Amount + parseInt(item.cost)
                      Total_SpecialDiscount = Total_SpecialDiscount + parseInt(item.special_discount)
               


                      return item.code
                    })
                   
                    var Arr_InV_REC_MODE = []
                    var Arr_InV_REC_DATE = []
                    var Arr_InV_REC_NO = []
                    var Arr_InV_REC_ShiFT_ID = []
                    var Arr_InV_REC_USER_ID = []
                    var Arr_InV_REC_USER_NAME = []
                    var Arr_InV_REC_REFUND = []
                    var Arr_InV_REC_RemarKS = []
                    var Arr_InV_AGainst_PreV_BalanCE = []
                    var Arr_InV_REC_VaLuE = []


                    var elements = []
                    var curr_date = ""

                    var temp1 = await CC.map((item) => {
                      elements = item.date.split("/")
                      curr_date = elements[1] + "/" + elements[0] + "/" + elements[2]
                      var transaction_date = new Date(curr_date)
                      transaction_date.setHours(15)
                      Arr_InV_REC_DATE.push(transaction_date.toISOString())
                      Arr_InV_REC_MODE.push(item.mode)
                      Arr_InV_REC_NO.push(item.cc)
                      Arr_InV_REC_ShiFT_ID.push("")
                      Arr_InV_REC_USER_ID.push(item.user_id)
                      Arr_InV_REC_USER_NAME.push(item.user_name)
                      Arr_InV_REC_REFUND.push(false)
                      Arr_InV_REC_RemarKS.push(item.remarks)
                      Arr_InV_AGainst_PreV_BalanCE.push(false)
                      Arr_InV_REC_VaLuE.push(item.amount + "")
                      return item.mode
                    })
                    Promise.all(invoice_table, temp1).then((values) => {
                      var invoice = {
                        
                        AirLine: airlineName,
                        airline_id:parseInt(airline.split("|")[0]),
                        airline_type:airlineType,

                        Branch_ID: branch_id,
                        User_ID: user_id,
                        Ref_No: Ref_No,
                        Panel_ID: selectedValue=="Normal" ? "" :selectedValue,
                        Ref_By_Doctor_ID: DoctorID + "",
                        Ref_By_Doctor_Name: DoctorName,
                        Patient_ID: id,
                        Ticket_No: ticket_no,
                        Flight_No: flight_no,
                        Flight_Date: flight_date.toISOString(),
                        Flight_Time: formatAMPM(flight_time),
                        Airport: airport,
                        Destination: destination,
                        SpecDiscount: Total_SpecialDiscount,
                        Discount: Total_Discount,
                        Amount: Total_Amount,
                        Entitlement_ID: Entitlement_Id + "",
                        Entitlement_Name: Entitlement_Name,
                        Received: Received_Payment,
                        Agent_ID: salesAgent_ID + "",
                        Comments_for_Patient: Comments_for_Patient,
                        Invoice_Remarks: Invoice_Remarks,
                        Agent_Name: salesAgent_Name,
                        Arr_InV_teST_ID: Arr_InV_teSt_ID,
                        Arr_InV_CodE: Arr_InV_CodE,
                        SampleDate: "",
                        SampleTime: "",
                        Arr_InV_TiTLE: Arr_InV_TiTLE,
                        Arr_InV_DesCP: Arr_InV_DesCP,
                        Arr_InV_LineS_naturE: Arr_InV_LineS_naturE,
                        Arr_InV_RatE: Arr_InV_RatE,
                        Arr_InV_DisC_P: Arr_InV_DisC_P,
                        Arr_InV_DisC_VaLuE: Arr_InV_DisC_VaLuE,
                        Arr_InV_DisC_SP: Arr_InV_DisC_SP,
                        Arr_InV_AMOUNT: Arr_InV_AMOUNT,
                        Arr_InV_REC_MODE: Arr_InV_REC_MODE,
                        Arr_InV_REC_DATE: Arr_InV_REC_DATE,
                        Arr_InV_REC_NO: Arr_InV_REC_NO,
                        Arr_InV_REC_ShiFT_ID: Arr_InV_REC_ShiFT_ID,
                        Arr_InV_REC_USER_ID: Arr_InV_REC_USER_ID,
                        Arr_InV_REC_USER_NAME: Arr_InV_REC_USER_NAME,
                        Arr_InV_REC_REFUND: Arr_InV_REC_REFUND,
                        Arr_InV_REC_RemarKS: Arr_InV_REC_RemarKS,
                        Arr_InV_AGainst_PreV_BalanCE: Arr_InV_AGainst_PreV_BalanCE,
                        Arr_InV_REC_VaLuE: Arr_InV_REC_VaLuE,
                        Arr_InV_REFUND: Arr_InV_REFUND,
                        Arr_InV_CANCEL: Arr_InV_CANCEL,
                        Arr_InV_AuX_reCS_ID: Arr_InV_AuX_reCS_ID,
                        Arr_InV_testS_GrouP_ID: Arr_InV_testS_GrouP_ID,
                        Arr_InV_testS_GrouP_TiTLE: Arr_InV_testS_GrouP_TiTLE,
                        ConsentForm: consentForm.split('base64,')[1],
                        PDF_String: ScanPDF.includes("base64,") ? ScanPDF.split('base64,')[1] : ScanPDF,
                        Picture_String: PatientImage.split('base64,')[1],
                        PDF_Name: ScanPDF != "" ? name + ".pdf" : "",
                        Image_String: ScanImage.split('base64,')[1],
                        Image_Name: ScanImage != "" ? name + ".png" : "",
                        Include_Previous_Report: "Yes",
                        SampleTakingPicture:SamplingPicture.split('base64,')[1],
                        Base64_ImagePassport:PassportImage.split('base64,')[1],
                         //20-jan-2023
                      if_FOC:foccheck,
                      // 30-sep-2024
                      // UrgentArray:UrgentArrayTemp
                      }
                      var vSearchStr = JSON.stringify(invoice);
                     
                      if (Balance_Payment > 0) {

                        if((selectedValue=="Normal" || selectedValue=="")  && Received_Payment==0  && Entitlement_Name==""){
                          alert("Sorry!, You can Balance Normal Patient Case! ")
                          setSaveInvoice(false)
                          return
                        }

                        var res = window.confirm('Are you Sure? Balance of Invoice will be Due ?')
                        if (res) {

                        } else {
                          setSaveInvoice(false)
                          return
                        }

                      }
                      console.log("4310 ################ 4310")
                      console.log(vSearchStr)
                      // 23-jan
                      var url = "https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoiceCreateNEW"
                      // var url = "https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoiceCreateNE"

                      fetch(url, {
                        method: "POST",

                        body: vSearchStr
                      }).then((res) => res.json()).then((response) => {
                        console.log(response)
                        if (response[0].Invoice_Status == 'Successful') {
                          alert("Invoice : MPL-" + response[0].Invoice_No + " Successfully Created")
                          localStorage.setItem('patient_mobile', "")
                          localStorage.setItem("patient_name", "")
                        // Show detail of test 17-dec-2022
                          setDetailShow(true)

                          window.location.href = "https://invoicing.mpl-labs.pk/Invoices"

                        } else {
                          if(response[0].shift_status=="Previous Day Shift Running"){
                            alert("Sorry Failed, Kindly Close Previous Shift!!")
                            window.location.href="https://invoicing.mpl-labs.pk/ShiftClosing"
                            setSaveInvoice(false)
                            return
                          }
                          alert("Sorry Failed, Kindly close Shift !!")
                          setSaveInvoice(false)
                        }
                      })

                    })


                  } else {
                    if (CNIC.trim() == '' && Selected_Test_Code_List.includes("COVIDPCR")) {
                      if(National=='true'){
                        if(CNIC.trim()==""){
                          alert('Failed, CNIC & Passport is compulsory for Passengers')
                          setSaveInvoice(false)
                          return
                        }
                      }
                    }
                    var user_id = localStorage.getItem('user_id')
                    var branch_id = localStorage.getItem('branch_id')
                    var selectedpanel = ""
                    if (NormalCase || selectedValue=="Normal") {
                      selectedpanel = ""
                    } else {
                      selectedpanel = selectedValue
                    }
                    var salesAgent_ID = ""
                    var salesAgent_Name = ""
                    var DoctorID = ""
                    var DoctorName = ""



                    if (Ref_By_Dr != '') {
                      DoctorID = Ref_By_Dr
                      DoctorName = DoctorNameList[DoctorID]
                    } else {
                      alert("Kindly Select Refer By")
                      setSaveInvoice(false)
                      return
                    }

                    if (salesAgent != "Select") {
                      salesAgent_ID = salesAgent
                      salesAgent_Name = salesAgentNameList[salesAgent_ID]
                    }
                    // 23-jan ye cond apply ki hai 100% waly function ko khtm kr k 
                    if(!foccheck){
                    if (Balance_Payment > 0) {
                      if((selectedValue=="Normal" || selectedValue=="")  && Received_Payment==0  && Entitlement_Name==""){
                        alert("Sorry!, You can't Due 100% Balance!! ")
                        setSaveInvoice(false)
                        return
                      }
                      var res = window.confirm('Are you Sure? Balance of Invoice will be Due ?')
                      if (res) {

                      } else {
                        setSaveInvoice(false)
                        return
                      }

                    }
                  }

                    var Arr_InV_teSt_ID = []
                    var Arr_InV_CodE = []
                    var Arr_InV_TiTLE = []
                    var Arr_InV_DesCP = []
                    var Arr_InV_LineS_naturE = []
                    var Arr_InV_RatE = []
                    var Arr_InV_DisC_P = []
                    var Arr_InV_DisC_VaLuE = []
                    var Arr_InV_DisC_SP = []
                    var Arr_InV_AMOUNT = []
                    var Arr_InV_REFUND = []
                    var Arr_InV_CANCEL = []
                    var Arr_InV_AuX_reCS_ID = []
                    var Arr_InV_testS_GrouP_ID = []
                    var Arr_InV_testS_GrouP_TiTLE = []
                    var Total_Discount = 0
                    var Total_SpecialDiscount = 0
                    var Total_Amount = 0
                    // 26-sep-2024
                    // var ArrRep_ProrityType = []
                    // var ArrRep_SampleTaken = []
                    // 30-sep-2024
                    var UrgentArrayTemp=[]
                    var SampleOutsideTemp=[]



                    var invoice_table = await data.map((item) => {
                      console.log(item)
                      Arr_InV_teSt_ID.push(item.Test_ID)
                      Arr_InV_CodE.push(item.code)
                      Arr_InV_TiTLE.push(item.title)
                      Arr_InV_DesCP.push(item.description)
                      Arr_InV_LineS_naturE.push("")
                      Arr_InV_RatE.push(item.cost + "")
                      Arr_InV_DisC_P.push(item.discount_percent + "")
                      Arr_InV_DisC_VaLuE.push(item.discount_value + "")
                      Arr_InV_DisC_SP.push(item.special_discount + "")
                      Arr_InV_AMOUNT.push(item.net_cost + "")
                      Arr_InV_REFUND.push(false)
                      Arr_InV_CANCEL.push(false)
                      Arr_InV_AuX_reCS_ID.push(0)
                      Arr_InV_testS_GrouP_ID.push(item.group_id)
                      Arr_InV_testS_GrouP_TiTLE.push(item.group_name)
                      Total_Discount = Total_Discount + parseInt(item.discount_value)
                      Total_Amount = Total_Amount + parseInt(item.cost)
                      Total_SpecialDiscount = Total_SpecialDiscount + parseInt(item.special_discount)
                             // 30-sep-2024
                      UrgentArrayTemp.push(urgentArray.find(obj => obj.code === item.code)?"Urgent":"")
                      SampleOutsideTemp.push(sampleOutsideArray.find(obj => obj.code === item.code)?"Outside":"")

                      // 26-sep-2024
                      // ArrRep_ProrityType.push("normal")
                      // ArrRep_SampleTaken.push("")
                      return item.code
                    })
                    console.log("Check Invoice Table")
                    console.log(UrgentArrayTemp)
                    var Arr_InV_REC_MODE = []
                    var Arr_InV_REC_DATE = []
                    var Arr_InV_REC_NO = []
                    var Arr_InV_REC_ShiFT_ID = []
                    var Arr_InV_REC_USER_ID = []
                    var Arr_InV_REC_USER_NAME = []
                    var Arr_InV_REC_REFUND = []
                    var Arr_InV_REC_RemarKS = []
                    var Arr_InV_AGainst_PreV_BalanCE = []
                    var Arr_InV_REC_VaLuE = []

                    var elements = []
                    var curr_date = ""

                    var temp1 = await CC.map((item) => {
                      elements = item.date.split("/")
                      curr_date = elements[1] + "/" + elements[0] + "/" + elements[2]
                      var transaction_date = new Date(curr_date)
                      transaction_date.setHours(15)
                      Arr_InV_REC_DATE.push(transaction_date.toISOString())
                      Arr_InV_REC_MODE.push(item.mode)
                      Arr_InV_REC_NO.push(item.cc)
                      Arr_InV_REC_ShiFT_ID.push("")
                      Arr_InV_REC_USER_ID.push(item.user_id)
                      Arr_InV_REC_USER_NAME.push(item.user_name)
                      Arr_InV_REC_REFUND.push(false)
                      Arr_InV_REC_RemarKS.push(item.remarks)
                      Arr_InV_AGainst_PreV_BalanCE.push(false)
                      Arr_InV_REC_VaLuE.push(item.amount + "")
                      return item.mode
                    })

                    var SearchFormData = {

                      AirLine: "",
                      airline_id:0,
                      airline_type:"",

                      Branch_ID: branch_id,
                      User_ID: user_id,
                      Ref_No: Ref_No,
                      Panel_ID: selectedpanel,
                      Patient_ID: id,
                      Ticket_No: "",
                      Airport: "",
                      Flight_No: "",
                      Flight_Date: "",
                      Flight_Time: "",
                      Ref_By_Doctor_ID: DoctorID + "",
                      Ref_By_Doctor_Name: DoctorName,
                      Destination: "",
                      SpecDiscount: Total_SpecialDiscount,
                      Discount: Total_Discount,
                      Amount: Total_Amount,
                      Comments_for_Patient: Comments_for_Patient,
                      Invoice_Remarks: Invoice_Remarks,
                      Received: Received_Payment,
                      Entitlement_ID: Entitlement_Id,
                      Entitlement_Name: Entitlement_Name,
                      Agent_ID: salesAgent_ID + "",
                      Agent_Name: salesAgent_Name,
                      SampleDate: "",
                      SampleTime: "",
                      Arr_InV_teST_ID: Arr_InV_teSt_ID,
                      Arr_InV_CodE: Arr_InV_CodE,
                      Arr_InV_TiTLE: Arr_InV_TiTLE,
                      Arr_InV_DesCP: Arr_InV_DesCP,
                      Arr_InV_LineS_naturE: Arr_InV_LineS_naturE,
                      Arr_InV_RatE: Arr_InV_RatE,
                      Arr_InV_DisC_P: Arr_InV_DisC_P,
                      Arr_InV_DisC_VaLuE: Arr_InV_DisC_VaLuE,
                      Arr_InV_DisC_SP: Arr_InV_DisC_SP,
                      Arr_InV_AMOUNT: Arr_InV_AMOUNT,
                      Arr_InV_REC_MODE: Arr_InV_REC_MODE,
                      Arr_InV_REC_DATE: Arr_InV_REC_DATE,
                      Arr_InV_REC_NO: Arr_InV_REC_NO,
                      Arr_InV_REC_ShiFT_ID: Arr_InV_REC_ShiFT_ID,
                      Arr_InV_REC_USER_ID: Arr_InV_REC_USER_ID,
                      Arr_InV_REC_USER_NAME: Arr_InV_REC_USER_NAME,
                      Arr_InV_REC_REFUND: Arr_InV_REC_REFUND,
                      Arr_InV_REC_RemarKS: Arr_InV_REC_RemarKS,
                      Arr_InV_AGainst_PreV_BalanCE: Arr_InV_AGainst_PreV_BalanCE,
                      Arr_InV_REC_VaLuE: Arr_InV_REC_VaLuE,
                      Arr_InV_REFUND: Arr_InV_REFUND,
                      Arr_InV_CANCEL: Arr_InV_CANCEL,
                      Arr_InV_AuX_reCS_ID: Arr_InV_AuX_reCS_ID,
                      Arr_InV_testS_GrouP_ID: Arr_InV_testS_GrouP_ID,
                      Arr_InV_testS_GrouP_TiTLE: Arr_InV_testS_GrouP_TiTLE,
                      // 30-SEP-2024
                      ArrRep_ProrityType:UrgentArrayTemp, 
                      ArrRep_SampleTaken:SampleOutsideTemp,

                      ConsentForm: "",
                      PDF_String: ScanPDF.includes("base64,") ? ScanPDF.split('base64,')[1] : ScanPDF,
                      PDF_Name: ScanPDF != "" ? name + ".pdf" : "",
                      Image_String: ScanImage.split('base64,')[1],
                      Image_Name: ScanImage != "" ? name + ".png" : "",
                      Include_Previous_Report: "Yes",
                      //20-jan-2023
                      if_FOC:foccheck

                    }
                    console.log("4542 ################ 4542")

                    var vSearchStr = JSON.stringify(SearchFormData);

                    console.log(vSearchStr)
                   
                    //23-jan

                    var url = "https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoiceCreateNEW"
                    // var url = "https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoiceCreateNE"


                    fetch(url, {
                      method: "POST",
                      body: vSearchStr
                    }).then((res) => res.json()).then((response) => {
                      console.log(response)
                      // 26-july-2023 Restrict Repetition Test 
                      if(response.Invoice_No=="Test(s) repetition!"){
                         alert("Test(s) Repetition Alert ! "+response.Invoice_Status)
                          setSaveInvoice(false)
                        return
                      }
                      if (response[0].Invoice_Status == 'Successful') {
                        alert("Invoice : MPL-" + response[0].Invoice_No + " Successfully Created")
                        localStorage.setItem('patient_mobile', "")
                        localStorage.setItem("patient_name", "")
                        fetch("https://reports.mpl-labs.pk:8443/4DACTION/BC_ROBO_SampleIDsCallingUp/" + response[0].Invoice_No).then((res) => res.json()).then((sample) => {
                          var date = new Date()
                          if(!Array.isArray(sample)){
                            // 25-july-2024
                            // window.location.href = "https://invoicing.mpl-labs.pk/Invoices"
                            history.push('/RB/Invoices');
                           
                            return
                          }
                          if (sample[0].Arr_Sample_ID == []) {
                            return
                          }
                          var tempDate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
                          // line 4227 and 4226 for refrence No and tag (MPL or Fsls ) for Robo and Barcode 31-dec
                          var obj = {
                            status: "pending",
                            id: id,
                            name: name,
                            title: sample[0].Arr_Type_Descp,
                            samples: sample[0].Arr_Sample_ID,
                            roboSlots: sample[0].Arr_RoboCode,
                            invoiceNo: response[0].Invoice_No,
                            Test_Codes: sample[0].Test_Codes,
                           
                            refrence_no:sample[0].ref_no,
                            idTag:sample[0].id_tag

                          }
                          Robo.database().ref("robo/Invoices/" + tempDate).once('value', async (data) => {
                            var res = data.toJSON()
                            console.log(res)
                            if (res == null) {

                              Robo.database().ref("robo/Invoices/" + tempDate).set(JSON.stringify([JSON.stringify(obj)])).then(() => {
                                // window.location.href = "https://invoicing.mpl-labs.pk/Invoices"
                                // 25-july-2024
                            // window.location.href = "https://invoicing.mpl-labs.pk/Invoices"
                            history.push('/RB/Invoices');
                              })
                            } else {
                              var temp = JSON.parse(res)
                              console.log(temp)
                              temp.push(JSON.stringify(obj))
                              Robo.database().ref("robo/Invoices/" + tempDate).set(JSON.stringify(temp)).then(() => {
                                // window.location.href = "https://invoicing.mpl-labs.pk/Invoices"
                                // 25-july-2024
                            // window.location.href = "https://invoicing.mpl-labs.pk/Invoices"
                            history.push('/RB/Invoices');
                              })
                            }


                         })


                       })


                      } else {
                        if(response[0].shift_status=="Previous Day Shift Running"){
                          alert("Sorry Failed, Kindly Close Previous Shift!!")
                          // window.location.href="https://invoicing.mpl-labs.pk/ShiftClosing"
                          // 25-july-2024
                            // window.location.href = "https://invoicing.mpl-labs.pk/Invoices"
                            history.push('/PP/ShiftClosing');
                          setSaveInvoice(false)
                          return
                        }
                        alert("Sorry Failed !!")

                        setSaveInvoice(false)
                      }
                   })
                  }

                }}
              >Save Invoice</Button>
            </Grid>

          </Grid>
        </div>


      </form>
      <br />
      <br />
      <div style={{ width: "100%" }}>

      </div>
      <br />
      <br />
      <br />
      <br />
      <br />

      {/* Group Test Info */}
      <Modal
        open={openGroupTests}
        onClose={handleCloseGroupTests}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper} style={{
          width:500,
           marginTop:30,
           marginBottom:10,
          // overflow:"auto"
          
        }}>
          <CancelOutlinedIcon onClick={handleCloseGroupTests}
           style={{
            // position:'absolute',
            // top:"33%",
            // right:"12%"
            marginTop:-10,
            marginLeft:"98%",
            cursor:"pointer"
            }}/>
          <MaterialTable
            options={{
              // 30em 
              // minBodyHeight: '27em',
              maxBodyHeight: '25em'
            }}
            columns={[
              { field: "name", title: "Test Name" }
            ]}
            data={GroupTestInfo}
            title={"Group of Tests"}
          />
        </div>
      </Modal>

       {/* Opened Shifts */}
       <Modal
        open={OpenShifts}
        onClose={()=>{
          alert("Close the Shifts!!!")
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
        <MaterialTable
            columns={[
              { title: "ID", field: "ArrShiftID" },
              { title: "Name", field: "ArrShiftName" },
              { title: "Date", field: "ArrShiftDate" },
              { title: "Branch", field: "ArrShiftBranch" },
              { title: "Select", field: "select" },
            ]}
            data={shifts}
            title={"Open Shifts (Please Close the following Shifts)"}
          />
        </div>
      </Modal>

      {/* Patient Selection */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper} >
          
          <MaterialTable
            columns={[
              { field: "Patient_ID", title: "ID" },
              { field: "ToAddress", title: "Title" },
              { field: "Patient_Name", title: "Name" },
              { field: "Age", title: "Age" },
              { field: "MobileNo", title: "Mobile" },
              { field: "eMail", title: "Email" },
              { field: "cnic", title: "CNIC" },
              { field: "select", title: "Select" },
            ]}
            data={search_table}
            title={"Patient Record"}
          />
        </div>
      </Modal>

      {/* Test Selection in group call */}
      <Modal
        open={openGroupCall}
        onClose={()=>{
          setGroupCall(false)
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper} style={{
          width:500
        }}>
          <MaterialTable
            options={{
              maxBodyHeight: '30em'
            }}
            columns={[
              { field: "group_name", title: "Group Name" },
              { field: "select", title: "Select" },
              // jo field me name likhen ge wo data me jo array dali hai us k sth match hota hai srf wohi display hota hai jo name field me aur groupList me hai wo nazar aae gi
              
            ]}
            data={groupList}
            title={"Call a Group"}
          />
        </div>
      </Modal>
       {/* Call a Group or patient history modal*/}
      <Modal
        open={openHist}
        onClose={handleCloseHist}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <MaterialTable
            options={{
              maxBodyHeight: '30em'
            }}
            columns={[
              { field: "id", title: "Invoice ID" },
              { field: "test_code", title: "Test Code" },
              { field: "test_title", title: "Test Title" },
              { field: "booking_date", title: "Booking Date" },
              { field: "booking_time", title: "Booking Time" },
              { field: "amount", title: "Amount" },
              { field: "discount", title: "Discount" },
              { field: "final", title: "Payable" },
              { field: "panel", title: "Panel Name" }
            ]}
            data={patient_history}
            title={"Patient History"}
          />
        </div>
      </Modal>

      {/* Importing Table when we click import button */}
      <Modal
        open={ImportMenu}
        onClose={handleImport}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <div>
            <TextField
              required
              id="outlined-required"
              label=""
              style={{
                borderRadius: 5,
                fontSize: 10,
                fontWeight: 'bold'
              }}
              value={ImportPatientID}
              onChange={(e) => {
                setImportPatientID(e.target.value)
              }}

            />
            <Button
              variant="contained"
              color="secondary"
              style={{
                textAlign: 'center',
                fontSize: '1em',
                height: "2em",
                float: 'right',
                padding: 10
              }}
              //firebase HomeSampling calling importPatientId Are those Who are type by textInput 4328
              //ye search data k lye hai import click kren modal me search patient click kren text input me importPatientId fill hoga 
              onClick={() => {
                HomeSampling.database().ref("patient/" + ImportPatientID).once('value', async (sampling) => {
                  var records = sampling.toJSON()
                  var the_rows = []
                  if (records == undefined || records == null) {
                    alert("No Home Sampling Found")
                    return
                  }
                  //############# records map start on click button ##################
                  Object.keys(records).map((key) => {
                    var obj = {
                      booking_id: key,
                      name: records[key].name,
                      mobile: records[key].contact_1,
                      select: <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={() => {
                          setName(records[key].name)
                          setMobile(records[key].contact_1)
                          var lab_tests = records[key].lab_tests
                          //############# label test map start #######################
                          Object.keys(lab_tests).map(async (key) => {
                            var testInfo = TestCallViaName[lab_tests[key].Test_Name]

                            if (Selected_Test_Code_List.includes(testInfo.Test_Code)) {
                              alert("Already Test Added!!")
                              return
                            }
                            var panel_id = localStorage.getItem('panel_id')
                            if (panel_id == null || panel_id == undefined) {
                              panel_id = ""
                            }

                            if (panel_id == "Normal") {
                              panel_id = ""
                            }
                            var obj={
                              airline_id:airline=="" ? "" : parseInt(JSON.parse(airline.split("|")[0])),
                              airline_type:airlineType=="NON-TRAVELLER" ? "" : airlineType,
                              Branch_ID: localStorage.getItem('branch_id'),
                              Panel_ID: selectedValue=="Normal" ? "" : selectedValue ,
                              Test_ID: testInfo.Test_ID+""
                            }
                            console.log(obj)
                            var vSearchStr = JSON.stringify(obj)
                            var url = "https://reports.mpl-labs.pk:8443/4DACTION/BookingInvoiceCallsPriceTest"
                      
                         

                            const Data_Json = await fetch(url, {
                              method: "POST",
                              'Content-Type': "application/json",
                              body: vSearchStr
                              })
                              // ########## after Api Calling resopnse is use ######## 
                            const response = await Data_Json.json()
                            var Apply_The_Discount_Price = 0
                            if (response[0].Test_Price == 0) {
                              alert("Sorry, Test Price is Zero")
                              return
                            }
                            if (Entitlement_Name.trim() != "") {
                              setDiscount(100)
                              setTempDiscount(100)

                              Apply_The_Discount_Price = total_cost + response[0].Test_Price
                            } else {
                              setDiscount(0)
                              setDiscountPrice(0)
                              setTempDiscount(0)
                              Apply_The_Discount_Price = 0
                            }
                            if (Entitlement_Name.trim() != "") {
                              var sum = parseInt(response[0].Test_Price) + total_cost
                              if (sum > parseInt(Entitlement_Amount)) {
                                alert("Entitlement Allocated Amount Reached!!")
                                return
                              }

                            }
                            var date = new Date(response[0].Delivery_Date).ddmmyyy()

                            var desp = date + " AT " + msToTime(response[0].Delivery_Time)
                            if (response[0].Delivery_Time == 0) {
                              desp = response[0].Actual_vs_Offered
                            }

                            var obj1 =
                            {
                              Test_ID: response[0].Test_ID,
                              code: response[0].Test_Code,
                              title: response[0].Test_Name,
                              description: desp,
                              cost: response[0].Test_Price,
                              discount_percent: 0,
                              discount_value: 0,
                              special_discount: 0,
                              net_cost: response[0].Test_Price,
                              AllowDiscountPercent:response[0].AllowDiscountPercent
                            }
                            setSelected_Test_Code_List([...Selected_Test_Code_List, response[0].Test_Code])
                            // Storage the Tests
                            var save_tests = [obj1]
                            if (localStorage.getItem('data') == "") {

                            } else {
                              console.log(localStorage.getItem('data'))
                              save_tests = JSON.parse(localStorage.getItem('data'))
                              save_tests.push(obj1)
                            }


                            localStorage.setItem('data', JSON.stringify(save_tests))
                            var all_test = [...save_tests]
                            console.log(all_test)
                            var temp_net_cost = 0
                            var payable = 0
                            var counter = 0
                            var index = 0
                            var calculating_discount = 0
                            var temp_discount_value = 0
                            var temp_total_cost = 0
                            var the_total_cost = total_cost + response[0].Test_Price
                            // allTest are fill by ...saveTest and saveTest are fill by obj1 and obj1 are fill by Api response 
                            //############### alltest map start ################################ 
                            all_test.map((row) => {
                              var vP = ((row.cost - row.discount_value) / (the_total_cost - total_discount_value)) * 100
                              console.log("Percentage of Row = " + vP)
                              var allocate_the_discount = Math.round((vP * Apply_The_Discount_Price) / 100)
                              if (all_test.length == (index + 1)) {
                                allocate_the_discount = Apply_The_Discount_Price - calculating_discount
                              }
                              calculating_discount = calculating_discount + allocate_the_discount
                              if (parseInt(row.cost) - parseInt(row.discount_value) == 0) {
                                return row
                              }
                              temp_net_cost = (parseInt(row.cost) - parseInt(row.discount_value)) - allocate_the_discount
                              console.log("Net Cost = " + temp_net_cost)
                              index = index + 1
                              if (temp_net_cost < 0) {
                                counter = counter + 1
                                return row

                              }
                             //  all the value that are calculate in above are use in below obj
                              var net_final_cost = temp_net_cost
                              payable = payable + net_final_cost
                              temp_discount_value = row.discount_value + temp_discount_value
                              temp_total_cost = temp_total_cost + row.cost
                              var obj = {
                                Test_ID: row.Test_ID,
                                code: row.code,
                                title: row.title,
                                description: row.description,
                                cost: row.cost,
                                AllowDiscountPercent:row.AllowDiscountPercent,
                                discount_percent: row.discount_percent,
                                discount_value: row.discount_value,
                                special_discount: allocate_the_discount,
                                net_cost: net_final_cost
                              }

                              return obj
                            }) 
                            //############### alltest map end ################################ 

                            if (counter == 0) {

                              setData(all_test)
                              setTotalSpecialDiscount(Apply_The_Discount_Price)
                              setTotalNetCost(payable)
                              var temp = parseInt((Apply_The_Discount_Price / (parseInt(temp_total_cost) - parseInt(temp_discount_value))) * 100)
                              if (Apply_The_Discount_Price == 0) {
                                temp = 0
                              }
                              setDiscount(temp)
                              setCC([])
                              setTotal_cost(temp_total_cost)
                              setTotalDiscountValue(temp_discount_value)
                              setBalance_Payment(payable)
                              setReceived_Payment(0)
                              document.getElementById("Lab Test Selection").value = ""
                              document.getElementById("Lab Test Selection").focus()

                            }


                          })
                          
                          //############# label test map end #######################
                          handleImport()
                          //handleImport to false the condition of modal true   setImportMenu(false);
                        }}
                      >
                        Select
                   </Button>
                    }
                    the_rows.push(obj)
                  })
                  setImportRecord(the_rows)
                })
                  //############# records map end 4353 on click button ##################
              }}> Search Patient</Button>
          </div>
          <div style={{
            paddingTop: 20
          }}>
            <MaterialTable
              columns={[
                { field: "booking_id", title: "Booking ID" },
                { field: "name", title: "Name" },
                { field: "mobile", title: "Mobile" },
                { field: "select", title: "Select" },
              ]}
              data={ImportRecord}
              title={"Home Sampling"}
            />
          </div>
        </div>

      </Modal>
    </div>
  );
}
const patients = [
  { name: "Rehan Raees", id: "mpl101", mobile: "03315999501" },
  { name: "Ali", id: "mpl2338", mobile: "03005031668" },
];
